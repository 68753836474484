import { PrivateApiClient } from "@sunrise/http-client";
import { type Language } from "@sunrise/i18n";
import { type TenantId } from "@sunrise/tenant";

import {
  type ChannelGroup,
  type ChannelGroupsResponse,
} from "./channel-groups.types";

export const channelGroupsEndpoints = {
  staticChannelGroups: (host: string, tenantId: TenantId, lang: Language) =>
    `${host}/channel-groups/tenants/${tenantId}/${lang}/full.json`,
  userChannelGroups: (host: string) => `${host}/alderaan/channel-groups/`,
};

export async function fetchUserChannelGroups(
  privateApi: PrivateApiClient,
  host: string,
): Promise<ChannelGroup[]> {
  const response = await privateApi.get<ChannelGroupsResponse>(
    channelGroupsEndpoints.userChannelGroups(host),
    {
      params: {
        group_type: "user",
      },
    },
  );

  return response.data.result;
}
