import { BaseError, type BaseErrorOptions } from "@sunrise/error";

/**
 * This means we could not decode the refresh token. And that causes us to no longer recover who is logged in.
 */
export class RefreshTokenBrokenError extends BaseError {
  constructor(message: string, options?: BaseErrorOptions) {
    super("RefreshTokenBrokenError", message, options);
  }
  /**
   * TokensMissingOrExpiredError will be handled by logging out the user.
   * So we need to let these errors propagate.
   *
   * Services that call a privateApi should not pretend that there is no data or something like that.
   */
  get canSwallow(): boolean {
    return false;
  }

  /**
   * There's no point in retrying whatever caused this error. The token is broken.
   */
  get shouldRetry(): boolean {
    return false;
  }
}
