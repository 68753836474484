import { hostsAtom, publicApi } from "@sunrise/http-client";
import { type Language, currentLanguageAtom } from "@sunrise/i18n";
import { type Nullable } from "@sunrise/utils";
import { settingsAtom } from "@sunrise/yallo-settings";
import { atomWithSuspenseQuery } from "jotai-tanstack-query";
import { isNil } from "lodash";

import { channelGroupsEndpoints } from "./channel-groups.service";
import {
  type ChannelGroup,
  type ChannelGroupsResponse,
} from "./channel-groups.types";

/**
 * This atom stores the channel groups that are available for the current tenant.
 * It does include custom channel groups.
 */
export const channelGroupsAtom = atomWithSuspenseQuery<
  Nullable<ChannelGroup[]>,
  unknown,
  Nullable<ChannelGroup[]>,
  Nullable<ChannelGroup[]>,
  [string, Language]
>((get) => ({
  queryKey: ["channel-groups", get(currentLanguageAtom)],
  queryFn: async ({ queryKey: [, lang] }) => {
    const settings = await get(settingsAtom);

    const tenantId = settings.data?.tenant.id;
    if (isNil(tenantId)) {
      throw new Error("TenantId is not defined");
    }

    const host = get(hostsAtom).data;
    if (isNil(host)) {
      throw new Error("Host is not defined");
    }

    const staticChannelGroups = await publicApi.get<ChannelGroupsResponse>(
      channelGroupsEndpoints.staticChannelGroups(host, tenantId, lang),
    );
    return staticChannelGroups.data.result;
  },
  cacheTime: Infinity,
  staleTime: Infinity,
  refetchOnMount: false,
}));

channelGroupsAtom.debugLabel = "channelGroupsAtom";
