import { useTranslator } from "@sunrise/translator";
import { type Nullable } from "@sunrise/utils";
import clsx from "clsx";
import type { ReactNode } from "react";

import styles from "./skip-button.module.css";

type SkipButtonProps = CommonProps & {
  visible: Nullable<boolean>;
  skipAvailableAfter: number;
  onSkip: () => void;
};

export function SkipButton({
  visible,
  skipAvailableAfter,
  onSkip,
  className,
  "data-testid": dataTestId = "skip-button",
}: SkipButtonProps): ReactNode {
  const t = useTranslator();

  // if skipAvailableAfter reached 0 or if ad is skippable but there is no skipAvailableAfter, show focused skip button
  if (visible) {
    return (
      <button
        className={clsx([styles.skipButton, className])}
        onClick={onSkip}
        data-testid={`${dataTestId}.skip-available`}
      >
        {t("ads_skip")}
      </button>
    );
  }

  // else show skip in X seconds button
  return (
    <div
      className={clsx([styles.skipAfter, className])}
      data-testid={`${dataTestId}.skip-not-available`}
    >
      {t("ads_skip_in", [skipAvailableAfter])}
    </div>
  );
}
