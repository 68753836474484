import { isNil } from "lodash";

import { DEFAULT_LANGUAGE } from "./constants";
import { type Language, type Localized } from "./i18n.types";

export function getLocalizedValue<T>(
  obj: Localized<T>,
  language: Language,
): T | null {
  const r = obj[language] ?? obj[DEFAULT_LANGUAGE];

  return isNil(r) ? null : r;
}
