import { hostsAtom } from "@sunrise/http-client";
import { currentLanguageAtom } from "@sunrise/i18n";
import { selectAccessToken } from "@sunrise/jwt";
import { Store } from "@sunrise/store";
import { Nullable } from "@sunrise/utils";
import { WebsocketService } from "@sunrise/yallo-websocket";

import { getClientID } from "@/modules/websocket/client-id";

/**
 * Ensures we create a new websocket connection whenever we log in.
 * It also disconnects when we log out a user.
 *
 * @param store
 */
export function initWebsocket(store: Store): Nullable<WebsocketService> {
  const { webSocket } = store.get(hostsAtom);

  if (!webSocket) {
    console.warn("Websocket URL has not been configured. Websocket disabled.");
    return;
  }

  const ws = new WebsocketService({ url: webSocket, store });

  if (
    import.meta.env.MODE === "development" ||
    import.meta.env.MODE === "localhost"
  ) {
    ws.on("unhandled", (parsed) => {
      // eslint-disable-next-line no-console
      console.log("unhandled message from server:", parsed);
    });
  }

  async function syncToken(): Promise<void> {
    // NOTE: Just selecting the accessToken does not work here somehow.
    const accessToken = store.get(selectAccessToken);

    if (!accessToken) {
      ws.disconnect();
    } else {
      ws.authenticate({
        token: accessToken,
        deviceId: getClientID(),
        userAgent: window.navigator.userAgent,
        clientVersion: import.meta.env.VITE_APP_VERSION,
      });
    }
  }

  store.sub(selectAccessToken, () => void syncToken());
  void syncToken();

  function setLanguage(): void {
    ws.setLanguage(store.get(currentLanguageAtom));
  }

  store.sub(currentLanguageAtom, setLanguage);

  return ws;
}
