import clsx from "clsx";

import styles from "./spinner.module.css";

type SpinnerProps = CommonProps;

const spinnerCount = 3;
const spinnerAnimationDelayMs = -150;
const spinners = Array.from(
  { length: spinnerCount },
  (_, idx) => ++idx * spinnerAnimationDelayMs + "ms",
).map((delay, idx) => (
  <div
    className={clsx(styles.circle)}
    key={idx}
    style={{ animationDelay: delay }}
  />
));

export function Spinner({
  "data-testid": dataTestId,
  className,
  style,
}: SpinnerProps): JSX.Element {
  return (
    <div
      style={style}
      className={clsx(styles.root, className)}
      data-testid={`${dataTestId}.spinner`}
    >
      {spinners}
      <div className={clsx(styles.circle)} />
    </div>
  );
}
