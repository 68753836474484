import { hostsAtom } from "@sunrise/http-client";
import { actionJWTSetTokens, jwtAtom, selectIsLoggedIn } from "@sunrise/jwt";
import { isNil } from "@sunrise/utils";
import { useAtomValue, useSetAtom } from "jotai";
import { useCallback, useEffect } from "react";

import { anonymousLogin } from "./auth.service";

export type AnonymousLoginReturn = {
  isLoggedIn: boolean;
  login: () => Promise<void>;
};

type UseAnonymousLoginProps = {
  autoLogin: boolean;
  deviceTypeName: string;
};

export function useAnonymousLogin({
  autoLogin,
  deviceTypeName,
}: UseAnonymousLoginProps): AnonymousLoginReturn {
  const dispatchJWT = useSetAtom(jwtAtom);
  const isLoggedIn = useAtomValue(selectIsLoggedIn);

  const hosts = useAtomValue(hostsAtom);
  const host = hosts.api;

  const login = useCallback(async () => {
    if (isLoggedIn) return;

    const resp = await anonymousLogin(hosts.api, deviceTypeName);
    if (!resp?.client_jwt_token) throw new Error("Missing access token");
    if (!resp?.client_jwt_refresh_token)
      throw new Error("Missing refresh token");

    dispatchJWT(
      actionJWTSetTokens({
        accessToken: resp.client_jwt_token,
        refreshToken: resp.client_jwt_refresh_token,
      }),
    );
  }, [dispatchJWT]);

  if (isNil(host)) throw new Error("Missing host");

  useEffect(() => {
    if (!autoLogin) return;
    if (isLoggedIn) return;
    void login();
  }, [dispatchJWT, isLoggedIn]);

  return {
    isLoggedIn,
    login,
  };
}
