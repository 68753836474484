import { VideoAdPlacementType } from "./ads.types";

export const endpoints = {
  alternativeVideoAds: (
    host: string,
    type: "replay" | "fast-forward" = "replay",
  ) => `${host}/nevarro/alternative-video-ads/${type}`,
  alternativePauseAds: (host: string) =>
    `${host}/nevarro/alternative-image-ads/pause`,
  videoAds: (host: string, placement: VideoAdPlacementType) =>
    `${host}/nevarro/video-ads/${placement}`,
};
