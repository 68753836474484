import { isLinearPlayRequest } from "@sunrise/player";
import { type PlayerManagerGuard as PMG } from "@sunrise/player-manager";
import { type Store } from "@sunrise/store";
import { nowSecondAtom } from "@sunrise/time";
import { type Nullable } from "@sunrise/utils";
import { ChannelId } from "@sunrise/yallo-channel";
import { ActiveReplayChannel, isInReplayWindow } from "@sunrise/yallo-replay";
import { UpsellError, UpsellErrorCode } from "@sunrise/yallo-upsell";

import {
  type PlayRequest,
  type PlayerManagerPermissions,
} from "./yallo-common-player-manager.types";

/**
 * This is a guard that checks if the user can play out a certain request.
 * It will throw UpsellErrors as needed.
 */
export class PlayerManagerGuard implements PMG<PlayRequest> {
  constructor(
    private readonly store: Store,
    private readonly getPermissions: (
      store: Store,
    ) => Nullable<PlayerManagerPermissions>,
    private readonly onUpsellError: (error: UpsellError) => void,
    private readonly isReplayChannelActive: (
      store: Store,
      channelId: ChannelId,
    ) => Promise<ActiveReplayChannel>,
    private readonly isChannelLockedForLive: (
      store: Store,
      channelId: ChannelId,
    ) => Promise<boolean>,
    private readonly hasUserHitLiveStreamLimit: () => Promise<boolean>,
  ) {}

  async canPlay(request: PlayRequest, silent?: boolean): Promise<boolean> {
    if (request.type === "replay") {
      if (!this.canReplay) {
        if (!silent) this.triggerUpsellError("UPSELL_NO_REPLAY");
        return false;
      }

      const { checkIfReplayActiveLongEnough } =
        await this.isReplayChannelActive(this.store, request.channelId);

      const isReplayActiveLongEnough = await checkIfReplayActiveLongEnough(
        request.epgId,
        request.startTime,
      );

      if (isReplayActiveLongEnough !== true) {
        if (!silent) this.triggerUpsellError(isReplayActiveLongEnough);
        return false;
      }

      if (
        request.startTime &&
        !isInReplayWindow(request.startTime, this.store.get(nowSecondAtom))
          .result
      ) {
        if (!silent) this.triggerUpsellError("UPSELL_OUTSIDE_REPLAY_WINDOW");
        return false;
      }

      return true;
    }

    if (!this.canPlayLive) {
      return false;
    }

    if (await this.isChannelLockedForLive(this.store, request.channelId)) {
      if (!silent) this.triggerUpsellError("UPSELL_CHANNEL_LOCKED");
      return false;
    }

    // If it's a live or replay PR, and the user has a limit to how long they can play such streams, check that here.
    // The requirement is to just check LIVE tv but functionally I think they mean that users should no longer be able to watch TV.
    // Unless it's recorded. It's just that free users can't replay at the moment. So it does not apply. But it does not hurt to check.
    if (
      isLinearPlayRequest(request) &&
      (await this.hasUserHitLiveStreamLimit())
    ) {
      if (!silent) {
        this.triggerUpsellError("UPSELL_EXCEEDED_STREAM_DURATION_LIMIT");
      }
      return Promise.resolve(false);
    }

    return true;
  }

  async canPause(request: PlayRequest, silent?: boolean): Promise<boolean> {
    // When we try to pause live stream we need to check if the channel is activated for replay.
    const { isChannelActiveForReplay } = await this.isReplayChannelActive(
      this.store,
      request.channelId,
    );

    if (isChannelActiveForReplay !== true) {
      if (!silent) this.triggerUpsellError(isChannelActiveForReplay);
      return Promise.resolve(false);
    }

    // When we pause a live stream, we become a replay stream.
    // So we need to check if we have basic replay permissions.
    if (request.type === "live" && !this.canReplay) {
      if (!silent) {
        this.triggerUpsellError("UPSELL_NO_PAUSE");
      }
      return Promise.resolve(false);
    }

    return Promise.resolve(true);
  }

  private triggerUpsellError(code: UpsellErrorCode): void {
    this.onUpsellError(new UpsellError(code));
  }

  private get canReplay(): boolean {
    const permissions = this.getPermissions(this.store);

    if (!permissions) {
      throw new Error("No Permissions found");
    }

    return permissions.canReplay;
  }

  private get canPlayLive(): boolean {
    const permissions = this.getPermissions(this.store);

    if (!permissions) {
      throw new Error("No Permissions found");
    }

    return permissions.canPlayLive;
  }
}
