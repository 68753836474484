import { type Nullable, isDefined } from "@sunrise/utils";
import type {
  LivePlayRequest,
  PlayRequest,
  ReplayPlayRequest,
} from "@sunrise/yallo-common-player-manager";

/**
 * Is the playrequest something we can map on EPG events in a timeline?
 */
export function isLinearPlayRequest(
  playRequest: Nullable<PlayRequest>,
): playRequest is LivePlayRequest | ReplayPlayRequest {
  return (
    isDefined(playRequest) &&
    (playRequest.type === "live" || playRequest?.type === "replay")
  );
}
