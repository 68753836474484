import { canSwallowError } from "@sunrise/error";
import { hostsAtom } from "@sunrise/http-client";
import { Nullable, isNil } from "@sunrise/utils";
import deepEqual from "fast-deep-equal";
import { atomWithQuery } from "jotai-tanstack-query";
import { atomFamily } from "jotai/utils";

import { VendorError } from "@/modules/vendor/vendor.error";
import { fetchVendor } from "@/modules/vendor/vendor.service";
import { Vendor } from "@/modules/vendor/vendor.types";

export const vendorQueryAtom = atomFamily(
  ({
    id,
    secret,
    host: vendorHost,
  }: {
    id: Nullable<string>;
    secret: Nullable<string>;
    host: string;
  }) =>
    atomWithQuery<Nullable<Vendor>>((get) => {
      const host = get(hostsAtom).ngApi;
      if (isNil(host)) throw new Error("Host is not set");

      return {
        queryKey: ["vendors", id],
        queryFn: async () => {
          if (!id || !secret) {
            const err = new VendorError("id/secret missing");
            console.error(err);
            throw err;
          }

          try {
            return await fetchVendor(host, id, secret, vendorHost);
          } catch (e) {
            if (canSwallowError(e)) {
              return null;
            }

            console.error(e);
            throw e;
          }
        },
        retry: false,
        retryOnMount: false,
        suspense: true,
        throwOnError: false,
        staleTime: Infinity,
        cacheTime: Infinity,
        refetchOnMount: false,
        refetchInterval: false,
      };
    }),
  deepEqual,
);
