import { BaseError } from "@sunrise/error";
import { getJWTState } from "@sunrise/jwt";
import { type Nullable } from "@sunrise/utils";
import axios, {
  AxiosError,
  type AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  type InternalAxiosRequestConfig,
  isAxiosError,
} from "axios";
import { isNil } from "lodash";

import { ForcedLogoutError } from "./errors/forced-logout.error";
import { MissingTokensError } from "./errors/missing-tokens.error";
import { RefreshTokenBrokenError } from "./errors/refresh-token-broken.error";
import { RefreshTokenExpiredError } from "./errors/refresh-token-expired.error";
import { RefreshTokenFailedError } from "./errors/refresh-token-failed.error";
import { RefreshTokenMissingError } from "./errors/refresh-token-missing.error";
import { isRetryableError } from "./helpers/is-retryable-error";
import {
  type RetryFunction,
  manualRetryAxiosInterceptor,
} from "./manual-retry-axios-interceptor";

function isNotAuthenticatedError(error: AxiosError): boolean {
  return !!(
    error.response?.status && [401, 403].includes(error.response.status)
  );
}

export type RefreshTokens = (
  host: string,
  refreshToken: string,
) => Promise<{
  client_jwt_refresh_token?: string;
  client_jwt_token?: string;
}>;

export class PrivateApiClient {
  constructor(
    protected readonly getAccessToken: () => Nullable<string>,
    protected readonly getRefreshToken: () => Nullable<string>,
    protected readonly setTokens: (at: string, rt: string) => void,
    protected readonly getHost: () => string,
    /**
     * Function is triggered when the privateApi deems it correct to reset the tokens and log out the user.
     * The error that caused the reset is still thrown.
     */
    protected readonly resetTokens: (error: BaseError) => void = () => {},
    protected readonly refreshTokens: RefreshTokens,
    handleRetry?: RetryFunction,
    protected readonly getRetryDelayInSeconds?: () => Nullable<number>,
  ) {
    this.client = axios.create();
    this.client.defaults.headers.common["X-Tenant"] = "yallo";
    this.client.defaults.headers.common["Content-Type"] = "application/json";
    this.client.interceptors.request.use(this.onAxiosRequest.bind(this));
    this.client.interceptors.response.use(
      undefined,
      this.onAxiosError.bind(this),
    );

    if (handleRetry) {
      manualRetryAxiosInterceptor(
        this.client,
        handleRetry,
        isRetryableError,
        getRetryDelayInSeconds,
      );
    }
  }

  private client: AxiosInstance;
  private refreshRequest: Nullable<Promise<unknown>>;

  async get<T, D = unknown>(
    url: string,
    config?: AxiosRequestConfig<D>,
  ): Promise<AxiosResponse<T, D>> {
    return this.client.get(url, config);
  }

  async post<T, D = unknown>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>,
  ): Promise<AxiosResponse<T, D>> {
    return this.client.post(url, data, config);
  }

  async delete<T, D = unknown>(
    url: string,
    config?: AxiosRequestConfig<D>,
  ): Promise<AxiosResponse<T, D>> {
    return this.client.delete(url, config);
  }

  private async onAxiosError(error: AxiosError): Promise<AxiosResponse> {
    if (error.config && isNotAuthenticatedError(error)) {
      // When we errored (means we were probably the first request to be hit by the 401)
      // we should refresh.
      await this.refreshToken();
      // And then we should make sure to retry the request.
      return this.client.request(error.config);
    }

    return Promise.reject(error);
  }

  private async onAxiosRequest(
    req: InternalAxiosRequestConfig,
  ): Promise<InternalAxiosRequestConfig> {
    if (!isNil(this.refreshRequest)) {
      // When something already decided that we need to refresh the token, wait for that to complete before performing the rest.
      await this.refreshRequest;
    }

    const accessToken = this.getAccessToken();
    const atState = getJWTState(accessToken);
    // when it's valid -> go with it
    if (atState === "valid") {
      req.headers.Authorization = createBearerToken(accessToken);
      return req;
    }

    const refreshToken = this.getRefreshToken();
    const rtState = getJWTState(refreshToken);

    switch (rtState) {
      case "error": {
        const err = new RefreshTokenBrokenError("refresh token broken");
        this.resetTokens(err);
        throw err;
      }
      case "expired": {
        const err = new RefreshTokenExpiredError("expired refresh token");
        this.resetTokens(err);
        throw err;
      }
      case "missing": {
        const err = new RefreshTokenMissingError("missing refresh token");
        this.resetTokens(err);
        throw err;
      }
    }

    // Refresh before we do the request (as we know there is no valid access token and we have a valid refresh token)
    await this.refreshToken();

    // When this succeeds it means our original request has received a new access & refresh token.
    req.headers.Authorization = createBearerToken(this.getAccessToken());
    return req;
  }

  private async refreshToken(): Promise<void> {
    if (!this.refreshRequest) {
      // When we already have a refresh request, return that.
      this.refreshRequest = this.refreshTokenInternal();
    }

    await this.refreshRequest;
  }

  private async refreshTokenInternal(): Promise<void> {
    const host = this.getHost();
    if (isNil(host)) throw new Error("No host found");

    const currentRefreshToken = this.getRefreshToken();
    if (isNil(currentRefreshToken))
      throw new MissingTokensError(
        "attempting to refresh without refresh token",
      );

    try {
      const response = await this.refreshTokens(host, currentRefreshToken);
      const refreshToken = response.client_jwt_refresh_token;
      const newAccessToken = response.client_jwt_token;

      if (!refreshToken || !newAccessToken) {
        // There are some odd cases of users logging out in the backend somehow.
        // Maybe the API somehow doesn't return any tokens. In that case, we are tracking it like this.
        // This will not trigger a logout in our case. We are keeping the old tokens.
        throw new MissingTokensError("missing tokens in refresh response");
      }
      this.setTokens(newAccessToken, refreshToken);
    } catch (e) {
      if (isAxiosError(e) && isNotAuthenticatedError(e)) {
        const err = new ForcedLogoutError("backend logged out user");
        this.resetTokens(err);
        throw err;
      }

      if (e instanceof BaseError) {
        throw e;
      }

      throw new RefreshTokenFailedError("failed to refresh token");
    } finally {
      // Should reset the refreshing promise else it'll keep it cached.
      this.refreshRequest = undefined;
    }
  }
}

function createBearerToken(token: Nullable<string>): Nullable<string> {
  return token ? `Bearer ${token}` : null;
}
