import { BaseError, type BaseErrorOptions } from "@sunrise/error";

/**
 * When the backend returns 401/403 on the refresh we know for sure that we can not recover.
 * So we are allowed to log out the user on the client.
 */
export class ForcedLogoutError extends BaseError {
  constructor(message: string, options?: BaseErrorOptions) {
    super("ForcedLogoutError", message, options);
  }
  /**
   * Error will be handled by logging out the user.
   * So we need to let these errors propagate.
   *
   * Services that call a privateApi should not pretend that there is no data or something like that.
   */
  get canSwallow(): boolean {
    return false;
  }

  get errorCode(): "error_user_logged_out" {
    return "error_user_logged_out";
  }

  /**
   * We can assume it's correct what the backend tells us and we do not need to retry.
   */
  get shouldRetry(): boolean {
    return false;
  }
}
