import { errorAtom } from "@sunrise/error";
import { actionJWTClear, actionJWTSetTokens, jwtAtom } from "@sunrise/jwt";
import { type Store } from "@sunrise/store";
import type { Nullable } from "@sunrise/utils";
import { isNil } from "lodash";

import { hostsAtom } from "./hosts.atom";
import type { RetryFunction } from "./manual-retry-axios-interceptor";
import { PrivateApiClient, type RefreshTokens } from "./private-api-client";

export const createPrivateApi = (
  store: Store,
  fetchRefreshAuthTokens: RefreshTokens,
  handleRetry?: RetryFunction,
  getRetryDelayInSeconds?: () => Nullable<number>,
): PrivateApiClient =>
  new PrivateApiClient(
    () => {
      const accessToken = store.get(jwtAtom).accessToken;
      if (isNil(accessToken)) throw new Error("No access token found");
      return accessToken;
    },
    () => {
      const refreshToken = store.get(jwtAtom).refreshToken;
      if (isNil(refreshToken)) throw new Error("No refresh token token found");
      return refreshToken;
    },
    (accessToken, refreshToken) => {
      store.set(jwtAtom, actionJWTSetTokens({ accessToken, refreshToken }));
    },
    () => {
      const host = store.get(hostsAtom).api;
      if (isNil(host)) throw new Error("No host found");
      return host;
    },
    (error) => {
      store.set(errorAtom, error);
      store.set(jwtAtom, actionJWTClear());
    },
    fetchRefreshAuthTokens,
    handleRetry,
    getRetryDelayInSeconds,
  );
