import { hostsAtom, publicApi } from "@sunrise/http-client";
import { isNil } from "@sunrise/utils";
import { atomWithSuspenseQuery } from "jotai-tanstack-query";

import { endpoints } from "./endpoints";

export const fairPlayCertificateAtom = atomWithSuspenseQuery<Uint8Array>(
  (get) => ({
    queryKey: ["fairPlayCertificate"],
    queryFn: async () => {
      const host = get(hostsAtom).clients;
      if (isNil(host)) throw new Error("Host is not set");

      const { data } = await publicApi.get<ArrayBuffer>(
        endpoints.appleFairPlayCertificate(host),
        {
          responseType: "arraybuffer",
        },
      );

      return new Uint8Array(data);
    },
    staleTime: Infinity,
    cacheTime: Infinity,
  }),
);
