import { useSeekbar } from "@sunrise/yallo-player-controls";
import { ReactElement, useState } from "react";

import styles from "./player-seekbar.module.css";

function PlayerSeekbar(): ReactElement {
  const [showDurationLeft, setShowDurationLeft] = useState(false);
  const { elapsed, durationLeft, progress } = useSeekbar();

  const toggleTimeNotation = (): void => setShowDurationLeft(!showDurationLeft);

  return (
    <div className={styles.seekbar}>
      <div className={styles.currentTime} onClick={toggleTimeNotation}>
        <span>{showDurationLeft ? `-${durationLeft}` : elapsed}</span>
      </div>
      <div
        className={styles.progress}
        style={{
          "--progress": `${progress ?? 0}%`,
        }}
      >
        <div className={styles.elapsed}></div>
        <div className={styles.thumb}></div>
      </div>
    </div>
  );
}

export { PlayerSeekbar };
