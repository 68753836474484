import {
  Language,
  TranslateFn,
  getDateFnsLocaleForLanguage,
} from "@sunrise/i18n";
import {
  addDays,
  format,
  getTime,
  intervalToDuration,
  isSameDay,
  subDays,
} from "date-fns";

import { isNil } from "./is-nil";
import { Nullable } from "./nullable";

export const HOUR_FORMAT = `HH:mm`;
export const DAY_FORMAT = "EEE dd.MM.yy";

/**
 * The business requirement is that we should not have live progress if the item is in the future or in the past.
 * Else, we should indicate how far along the current epg item is.
 */
export function getLiveProgress(
  start: Date,
  end: Date,
  now: Date,
  /**
   * When force is set, we will return 100% if the item is in the past.
   */
  force?: boolean,
): number | undefined {
  const startTime = getTime(start);
  const endTime = getTime(end);
  const nowTime = getTime(now);

  // if program hasn't started today or is in the future day
  if (nowTime < startTime) return undefined;
  // if program has finished or is in the past
  if (nowTime > endTime) return force ? 100 : undefined;

  // NOTE: 8 gives us 0.125% steps to make the progress bar smoother
  return (
    Math.round(((nowTime - startTime) / (endTime - startTime)) * 100 * 8) / 8
  );
}

const DEFAULT_TIME_FORMAT = "00:00:00";
const pad = (value: number | string) => value.toString().padStart(2, "0");

export function getLiveElapsedTime(start: Date, now?: Nullable<Date>): string {
  if (!now) return DEFAULT_TIME_FORMAT;

  const startTime = getTime(start);
  const nowTime = getTime(now);

  if (nowTime < startTime) return DEFAULT_TIME_FORMAT;

  const {
    hours = "00",
    minutes = "00",
    seconds = "00",
  } = intervalToDuration({ start: startTime, end: nowTime });

  return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
}

/**
 * format streaming usage to 0 or 1 decimal place
 * @param seconds
 */
export function formatStreamingUsage(seconds: Nullable<number>): string {
  if (isNil(seconds) || isNaN(seconds)) return "";
  const hours = seconds / 60 / 60;
  return hours % 1 != 0 ? hours.toFixed(1) : hours.toFixed(0);
}

export async function getDayOfWeekTranslation(
  start: Date,
  now: Date,
  language: Language,
  t: TranslateFn,
): Promise<string> {
  const locale = await getDateFnsLocaleForLanguage(language);

  if (isSameDay(start, now)) {
    return t("today");
  }

  if (isSameDay(start, subDays(now, 1))) {
    return t("yesterday");
  }

  if (isSameDay(start, addDays(now, 1))) {
    return t("tomorrow");
  }

  return format(start, DAY_FORMAT, { locale });
}
