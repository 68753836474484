import { hostsAtom, publicApi } from "@sunrise/http-client";
import { type TimeDay, dateToTimeDay, nowAtom } from "@sunrise/time";
import { type ChannelId } from "@sunrise/yallo-channel";
import { add, addDays } from "date-fns";
import { atom } from "jotai";
import { atomFamily, selectAtom, unwrap } from "jotai/utils";
import { isEqual, isNil } from "lodash";
import { Nullable } from "vitest";

import { programIsPlayingAtTime } from "../main";
import { type EPGEntry, type EPGEntryId } from "./epg.types";

export const endpoint = {
  /**
   * @returns URL to single EPG entry
   */
  epgEntry: (host: string, epgId: EPGEntryId) =>
    `${host}/epg/entry/${epgId}/full.json`,
  /**
   * @returns URL to collection of **single channel**'s EPGs for **entire day**
   */
  epgCollectionPerChannelPerDay: (
    host: string,
    day: TimeDay,
    channelId: ChannelId,
  ) => `${host}/epg/day/${day}/${channelId}/full.json`,
};

export type EPGEntryCollectionPerChannelPerDayResponse = {
  result: EPGEntry[];
};

async function fetchSingleChannelEPGsPerEntireDay(
  host: string,
  day: TimeDay,
  channelId: ChannelId,
): Promise<EPGEntry[]> {
  const { data } =
    await publicApi.get<EPGEntryCollectionPerChannelPerDayResponse>(
      endpoint.epgCollectionPerChannelPerDay(host, day, channelId),
    );

  return data.result;
}

/**
 * Fetches single {@link EPGEntry}
 */
export async function fetchEPGEntry(
  host: string,
  id: EPGEntryId,
): Promise<EPGEntry> {
  const { data } = await publicApi.get<EPGEntry>(endpoint.epgEntry(host, id));
  return data;
}

/**
 * Fetches and stores {@link EPGEntry} collection per single channel per day.
 */
export const epgEntryCollectionPerChannelPerDayAtom = atomFamily(
  (param: { day: TimeDay; channelId: ChannelId }) => {
    const innerAtom = atom(async (get) => {
      const host = get(hostsAtom).data;
      if (isNil(host)) throw new Error("Host is not set");

      return fetchSingleChannelEPGsPerEntireDay(
        host,
        param.day,
        param.channelId,
      );
    });
    innerAtom.debugLabel = `epgEntryCollectionPerChannelPerDayAtom(${param.day}, ${param.channelId})`;
    return innerAtom;
  },
  isEqual,
);

/**
 * @returns EPG entries per single channel per day
 */
export const selectEPGEntriesPerDay = atomFamily(
  (param: { day: TimeDay; channelId: ChannelId }) => {
    const innerAtom = epgEntryCollectionPerChannelPerDayAtom({
      channelId: param.channelId,
      day: param.day,
    });
    innerAtom.debugLabel = `selectEPGEntriesPerDay(${param.day}, ${param.channelId})`;
    return innerAtom;
  },
  isEqual,
);

/**
 * An atom which returns a function so we can ask what is playing at a specific time.
 * The problem is that the EPG data we get from the backend is not always correct.
 * When we ask for the EPG data on a specific day, it will not contain the first item of the day.
 */
export const getEpgEntryPlayingAtTimeOnChannel = atomFamily(
  (param: { channelId: ChannelId }) => {
    const innerAtom = atom((get) => {
      async function findForTime(time: Date, dayOffset = 0) {
        const day = dateToTimeDay(addDays(time, dayOffset), true);

        return (
          await get(
            selectEPGEntriesPerDay({
              day,
              channelId: param.channelId,
            }),
          )
        ).find((it) => {
          return programIsPlayingAtTime(
            {
              startTime: new Date(it.actual_start),
              endTime: new Date(it.actual_end),
            },
            time,
          );
        });
      }

      return async (time: Date) => {
        return (
          (await findForTime(time)) ?? (await findForTime(time, -1)) ?? null
        );
      };
    });
    innerAtom.debugLabel = `getEpgEntryPlayingAtTimeOnChannel(${param.channelId})`;
    return innerAtom;
  },
  isEqual,
);

/**
 * @returns Nth EPG entry per single channel per day
 */
const selectNthEPGEntryPerDay = atomFamily(
  (param: { day: TimeDay; channelId: ChannelId; nth: number }) => {
    const inner = selectAtom(
      unwrap(
        selectEPGEntriesPerDay({
          day: param.day,
          channelId: param.channelId,
        }),
      ),
      (s) => s?.[param.nth],
    );
    inner.debugLabel = `selectNthEPGEntryPerDay(${param.day}, ${param.channelId}, ${param.nth})`;
    return inner;
  },
  isEqual,
);

/**
 * Atom that returns current live EPG.
 *
 * @throws if no EPGs found for given time frame or if no live EPG found
 */
export const currentLiveEPGEntryAtom = atomFamily((channelId: ChannelId) => {
  const innerAtom = atom(
    async (get): Promise<Nullable<[entry: EPGEntry, index: number]>> => {
      // subscribe to now so we can recompute atom when it changes
      const now = get(nowAtom);

      const day = dateToTimeDay(now, true);
      const egpEntries = await get(
        selectEPGEntriesPerDay({
          channelId,
          day,
        }),
      );

      const matchedIdx = egpEntries.findIndex((it) => {
        return programIsPlayingAtTime(
          {
            startTime: new Date(it.actual_start),
            endTime: new Date(it.actual_end),
          },
          now,
        );
      });

      const epg = await get(
        selectNthEPGEntryPerDay({
          channelId,
          day,
          nth: matchedIdx,
        }),
      );

      return epg ? [epg, matchedIdx] : null;
    },
  );
  innerAtom.debugLabel = `currentLiveEPGEntryAtom(${channelId})`;
  return innerAtom;
});

/**
 * Atom that returns next live EPG.
 *
 * @throws if no EPGs found for given time frame or if no live EPG found
 */
export const nextLiveEPGEntryAtom = atomFamily((channelId: ChannelId) => {
  const innerAtom = atom(async (get): Promise<Nullable<EPGEntry>> => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, liveEgpIdx] =
      (await get(currentLiveEPGEntryAtom(channelId))) ?? [];

    const now = get(nowAtom);
    const today = dateToTimeDay(now, true);

    const nextLiveEpgEntry = isNil(liveEgpIdx)
      ? null
      : await get(
          selectNthEPGEntryPerDay({
            channelId,
            nth: liveEgpIdx + 1,
            day: today,
          }),
        );
    if (!isNil(nextLiveEpgEntry)) return nextLiveEpgEntry;

    const tomorrow = dateToTimeDay(add(now, { days: 1 }), true);
    return get(
      selectNthEPGEntryPerDay({
        channelId,
        nth: 0,
        day: tomorrow,
      }),
    );
  });
  innerAtom.debugLabel = `nextLiveEPGEntryAtom(${channelId})`;
  return innerAtom;
});
