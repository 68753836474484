import { BaseError, BaseErrorOptions } from "@sunrise/error";

export class VendorError extends BaseError {
  constructor(message: string, opts?: BaseErrorOptions) {
    super("VendorError", message, opts);
  }

  // eslint-disable-next-line @typescript-eslint/class-literal-property-style
  get shouldTrace(): boolean {
    return false;
  }

  // eslint-disable-next-line @typescript-eslint/class-literal-property-style
  get canSwallow(): boolean {
    return false;
  }

  get shouldRetry(): boolean {
    return super.shouldRetry;
  }
}
