import { initPauseAds, initVideoAds } from "@sunrise/ads";
import { initAnalytics } from "@sunrise/analytics";
import { fetchRefreshAuthTokens } from "@sunrise/auth";
import { errorAtom } from "@sunrise/error";
import {
  createPrivateApi,
  hostsAtom,
  httpClientAtom,
  publicApi,
} from "@sunrise/http-client";
import { type Language, currentLanguageAtom } from "@sunrise/i18n";
import { initMonitoring } from "@sunrise/monitoring";
import {
  getVideoElement,
  initVideoPlayer,
  playerDelayedBufferSettingsAtom,
  playerLiveBufferSettingsAtom,
} from "@sunrise/player";
import {
  actionPlayerStatsSetEnabled,
  playerStatsAtom,
} from "@sunrise/player-stats";
import { type Store, StoreProvider } from "@sunrise/store";
import {
  TranslationProvider,
  getTranslationFileAtom,
} from "@sunrise/translator";
import "@sunrise/web-common-styles/base.css";
import "@sunrise/web-common-styles/reset.css";
import { initPlayerManager } from "@sunrise/yallo-common-player-manager";
import { QueryClient } from "@tanstack/query-core";
import { queryClientAtom } from "jotai-tanstack-query";
import { isNil } from "lodash";
import { ReactElement, ReactNode } from "react";
import { createRoot } from "react-dom/client";

import { yalloStore } from "@/core";
import { getTranslationFile } from "@/modules/i18n/get-translation-file";
import { initPreventSeeking } from "@/modules/player/init-prevent-seeking";
import {
  HydrateQueryAtoms,
  QueryProvider,
  queryClient,
} from "@/modules/query-provider";
import { initWebsocket } from "@/modules/websocket";
import Player from "@/routes/player";
import "@/styles/fonts.css";
import "@/styles/global.css";

import { initFullscreenManager } from "./modules/fullscreen";

const environment = import.meta.env.MODE;
const appVersion = import.meta.env.VITE_APP_VERSION;
const isProdMode = environment === "production";

// TODO: Read value from env variable defined on AWS
// We fallback to null when the value is falsey ("") for example.
const dsn = import.meta.env.VITE_SENTRY_DSN || null;

initMonitoring(
  {
    dsn,
    environment,
    isProdMode,
  },
  {
    initialScope: (scope) => {
      return scope;
    },
  },
);

/**
 * Initialize libraries
 */
function initIntegrations(): void {
  yalloStore.set(httpClientAtom, {
    privateApi: createPrivateApi(yalloStore, fetchRefreshAuthTokens),
    publicApi,
  });
  yalloStore.set(hostsAtom, {
    api: import.meta.env.VITE_API_ENDPOINT,
    ngApi: import.meta.env.VITE_NG_API_ENDPOINT,
    clients: import.meta.env.VITE_CLIENTS_ENDPOINT,
    data: import.meta.env.VITE_DATA_ENDPOINT,
    webSocket: import.meta.env.VITE_WEBSOCKET_ENDPOINT,
  });
  yalloStore.set(getTranslationFileAtom, { fn: getTranslationFile });

  initPlayerManager(yalloStore, import.meta.env.MODE === "production");
  initVideoAds(
    yalloStore,
    getVideoElement,
    () => {},
    import.meta.env.MODE !== "production",
  );
  initPauseAds(yalloStore);
  initVideoPlayer(
    yalloStore,
    {
      showPlayer: () => {},
      getPlayerBufferSettings: {
        live: () => yalloStore.get(playerLiveBufferSettingsAtom),
        delayed: () => yalloStore.get(playerDelayedBufferSettingsAtom),
      },
      // Make sure to inject the player error into the regular error atom so we can catch it before the ErrorBoundary.
      onError: (e) => yalloStore.set(errorAtom, e),
      isEnabled: !window.disablePlayer,
    },
    (playerController) => {
      // eslint-disable-next-line no-console
      console.log("Using", playerController.getPlayerWrapperName());
    },
  );

  initPreventSeeking(yalloStore);

  const ws = initWebsocket(yalloStore);
  if (ws) {
    initAnalytics(yalloStore, false, ws);
  }

  if (import.meta.env.MODE === "test") {
    yalloStore.set(currentLanguageAtom, "de" as Language);
  }

  // For testing purposes
  if (
    import.meta.env.MODE !== "production" &&
    import.meta.env.VITE_PLAYER_STATS_DEFAULT === "true"
  ) {
    yalloStore.set(playerStatsAtom, actionPlayerStatsSetEnabled());
  }

  initFullscreenManager(yalloStore);
}

function logVersion(): void {
  if (!import.meta.env.PROD) {
    return;
  }

  console.info(`mode: ${import.meta.env.MODE}
version: ${appVersion}
commit: ${import.meta.env.VITE_APP_COMMIT_HASH}
build: ${import.meta.env.VITE_APP_BUILD_NR} ${
    import.meta.env.VITE_APP_BUILD_DATE
      ? `(${new Date(
          Number(import.meta.env.VITE_APP_BUILD_DATE),
        ).toLocaleString()})`
      : ""
  }`);
}

addEventListener("DOMContentLoaded", () => {
  logVersion();

  const appNode = document.getElementById("app");
  if (isNil(appNode)) throw new Error("App element does not exist");

  initIntegrations();

  createRoot(appNode).render(
    <AppProvider
      storeInstance={yalloStore}
      queryClientAtom={queryClientAtom}
      queryClient={queryClient}
      enableDevTools={!import.meta.env.PROD}
    >
      <Root />
    </AppProvider>,
  );
});

export function AppProvider(props: {
  children: ReactNode;
  queryClient: QueryClient;
  enableDevTools: boolean;
  queryClientAtom: typeof queryClientAtom;
  storeInstance: Store;
}): ReactNode {
  return (
    <QueryProvider
      queryClientInstance={props.queryClient}
      enableDevTools={props.enableDevTools}
    >
      <StoreProvider store={yalloStore} enableDevTools={props.enableDevTools}>
        <HydrateQueryAtoms queryClientAtom={props.queryClientAtom}>
          <TranslationProvider>{props.children}</TranslationProvider>
        </HydrateQueryAtoms>
      </StoreProvider>
    </QueryProvider>
  );
}

function Root(): ReactElement {
  return <Player />;
}
