import { hostsAtom, publicApi } from "@sunrise/http-client";
import { atomWithSuspenseQuery } from "jotai-tanstack-query";
import { isNil } from "lodash";

import { type Settings } from "./settings.types";

export const endpoint = {
  settings: (host: string) => `${host}/settings/settings_tizen_v8.json`,
};

export const settingsAtom = atomWithSuspenseQuery<Settings>((get) => ({
  queryKey: ["settings"],
  queryFn: async () => {
    const host = get(hostsAtom).clients;
    if (isNil(host)) throw new Error("Host is not set");

    return (await publicApi.get<Settings>(endpoint.settings(host))).data;
  },
  staleTime: Infinity,
  cacheTime: Infinity,
}));
