import { BaseError, errorAtom } from "@sunrise/error";
import { useTranslatable } from "@sunrise/translator";
import { Nullable } from "@sunrise/utils";
import {
  DialogButtonType,
  ErrorDialogConfiguration,
  GetErrorButtonFn,
  getErrorDialogConfiguration,
} from "@sunrise/yallo-error-mapping";
import { useAtomValue, useStore } from "jotai";
import { ReactNode, useCallback, useEffect, useState } from "react";

import { Button } from "@/components/button";

import styles from "./error-overlay.module.css";

/**
 * This component is responsible to show the errors that are put in the atoms which need to be shown to the users.
 * These errors are not really blocking errors but they may cause a certain interaction to fail.
 *
 * Whatever triggered the error should have also cleaned up after itself.
 * For example, if playout failed because the user's tokens were expired or because refresh showed the user no longer exists,
 * it should have already logged out the user.
 *
 * This component does not do any cleanup. It only shows the error and provides a way to reload the page.
 */
export function ErrorOverlay(): ReactNode {
  const t = useTranslatable();
  const store = useStore();
  const error = useAtomValue(errorAtom);
  const [dialog, setDialog] = useState<Nullable<ErrorDialogConfiguration>>();

  const reload = useCallback(() => window.location.reload(), []);

  const getButtonAction: GetErrorButtonFn = useCallback(
    (code?: DialogButtonType): (() => void) => {
      switch (code) {
        default:
          return reload;
      }
    },
    [reload],
  );

  useEffect(() => {
    if (!error) return;

    const getDialog = async (): Promise<void> => {
      const dialogConfig = await getErrorDialogConfiguration(
        error,
        getButtonAction,
        store,
        {
          forceResult: true,
        },
      );

      setDialog(dialogConfig);
    };

    getDialog();
  }, [error, getButtonAction, store]);

  const isBaseError = error instanceof BaseError;
  const errorCode = isBaseError ? error.errorCode : null;

  if (!dialog || !error) return;

  return (
    <div
      className={styles.container}
      data-testid="error-code"
      data-errorcode={errorCode ?? error.name}
    >
      <h1 className={styles.title} data-testid="error-title">
        {t(dialog.title)}
      </h1>
      <h2 className={styles.description} data-testid="error-description">
        {t(dialog.description)}
      </h2>
      <Button variant="filled" onClick={reload}>
        {t({ key: "button_embedded_error_reload" })}
      </Button>
    </div>
  );
}
