import type { Translatable } from "@sunrise/i18n";
import type { Nullable } from "@sunrise/utils";
import { useCallback } from "react";

import { useTranslator } from "./use-translator";

export function useTranslatable() {
  const t = useTranslator();

  return useCallback(
    (value: Nullable<Translatable>): string | null => {
      if (!value) {
        return null;
      }

      if (typeof value === "string") {
        return value;
      }

      function translateDynamic(value: { key: string; params?: unknown[] }) {
        if ("params" in value) {
          return t(value.key, value.params);
        }

        return t(value.key);
      }

      if (Array.isArray(value)) {
        return value.map((item) => translateDynamic(item)).join(" ");
      }

      if (typeof value === "object" && "key" in value && value.key) {
        return translateDynamic(value);
      }

      return null;
    },
    [t],
  );
}
