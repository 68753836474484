import type { AssetId } from "@sunrise/asset";
import {
  type PagedResponse,
  type PrivateApiClient,
} from "@sunrise/http-client";
import { type Nullable } from "@sunrise/utils";
import { type EPGEntryId } from "@sunrise/yallo-epg";
import { isAxiosError } from "axios";

import { endpoints } from "./endpoints";
import { RecordingsError } from "./errors/RecordingsError";
import {
  type CreateRecordingSchedule,
  type RecordingGroupId,
  type RecordingGroupSeries,
  type RecordingId,
  type RecordingSchedule,
  type RecordingScheduleType,
  type RecordingStats,
  type RecordingStatusResponse,
  type Recordings,
  type RecordingsResponse,
  type SingleRecording,
} from "./recordings.types";

export async function fetchSingleRecording(
  host: string,
  httpClient: PrivateApiClient,
  recordingId: RecordingId,
): Promise<Nullable<SingleRecording>> {
  const { data } = await httpClient.get<SingleRecording>(
    endpoints.singleRecording(host, recordingId),
  );
  return { ...data, type: "recording" };
}

export async function deleteSingleRecording(
  host: string,
  httpClient: PrivateApiClient,
  recordingId: RecordingId,
): Promise<void> {
  await httpClient.delete<SingleRecording>(
    endpoints.singleRecording(host, recordingId),
  );
}

export async function fetchRecordings(
  host: string,
  httpClient: PrivateApiClient,
  searchParam: {
    page: number;
    pagesize?: number;
  },
): Promise<RecordingsResponse> {
  return httpClient.get(endpoints.recordingItems(host, searchParam));
}

export async function fetchRecordingStatuses(
  host: string,
  httpClient: PrivateApiClient,
  pageSize: number = 1000,
): Promise<RecordingStatusResponse> {
  const url = new URL(endpoints.recordingStatus(host));

  url.searchParams.append("pagesize", pageSize.toString());

  const { data } = await httpClient.get<RecordingStatusResponse>(url.href);

  return data;
}

export async function fetchRecordingStats(
  host: string,
  httpClient: PrivateApiClient,
): Promise<RecordingStats> {
  const { data } = await httpClient.get<RecordingStats>(
    endpoints.recordingStats(host),
  );

  return data;
}

export async function createRecordingSchedule(
  host: string,
  httpClient: PrivateApiClient,
  epgEntryId: EPGEntryId,
  providerName: string,
  type: RecordingScheduleType = "single",
): Promise<RecordingSchedule> {
  try {
    const { data } = await httpClient.post<
      RecordingSchedule,
      CreateRecordingSchedule
    >(endpoints.recordingSchedules.create(host), {
      epg_entry: {
        id: epgEntryId,
      },
      provider_name: providerName,
      type,
    });

    return data;
  } catch (err) {
    if (
      isAxiosError(err) &&
      err.response?.status === 412 &&
      typeof err.response?.data === "object" &&
      err.response.data !== null &&
      "message" in err.response.data &&
      typeof err.response.data.message === "string"
    ) {
      throw new RecordingsError("failed to create recording", {
        errorCode: err.response.data.message,
        origin: err,
      });
    }

    throw err;
  }
}

export async function deleteRecordingSchedule(
  host: string,
  httpClient: PrivateApiClient,
  epgEntryId: EPGEntryId,
): Promise<void> {
  await httpClient.delete<RecordingSchedule, CreateRecordingSchedule>(
    endpoints.recordingSchedules.deleteByEpgEntryId(host, epgEntryId),
  );
}

export async function cancelRecordings(
  host: string,
  httpClient: PrivateApiClient,
  assetId: AssetId,
): Promise<void> {
  await httpClient.delete<RecordingSchedule, CreateRecordingSchedule>(
    endpoints.recordingSchedules.cancelByAssetId(host, assetId),
  );
}

export async function deleteRecordings(
  host: string,
  httpClient: PrivateApiClient,
  assetId: AssetId,
): Promise<void> {
  await httpClient.delete<RecordingSchedule, CreateRecordingSchedule>(
    endpoints.recordingGroupsByAssetId(host, assetId),
  );
}

export async function haveRecordingSchedules(
  host: string,
  httpClient: PrivateApiClient,
  assetId: AssetId,
): Promise<boolean> {
  const { data } = await httpClient.get<Recordings>(
    endpoints.recordingSchedules.haveRecordingSchedules(host, assetId),
  );

  return data.records > 0;
}

export async function fetchRecordingGroupItems(
  host: string,
  httpClient: PrivateApiClient,
  recordingGroupId: RecordingGroupId,
  traverseChildGroups: boolean,
  page: number = 1,
  pageSize: number = 12,
): Promise<Nullable<PagedResponse<SingleRecording>>> {
  const url = new URL(
    endpoints.recordingGroups.recordingsGroupItems(
      host,
      recordingGroupId,
      traverseChildGroups,
    ),
  );
  url.searchParams.append("pagesize", pageSize.toString());
  url.searchParams.append("page", page.toString());

  const { data } = await httpClient.get<PagedResponse<SingleRecording>>(
    url.href,
  );
  return data;
}

export async function fetchRecordingGroup(
  host: string,
  httpClient: PrivateApiClient,
  recordingGroupId: RecordingGroupId,
) {
  const url = endpoints.recordingGroups.recordingsGroup(host, recordingGroupId);
  const { data } = await httpClient.get<RecordingGroupSeries>(url);

  return data;
}
