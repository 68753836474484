"use client";

import { useAtomValue } from "jotai";
import { PropsWithChildren, ReactNode, createContext, useContext } from "react";
import { type Translations } from "translations";

import { translationQueryAtom } from "./translation.query";

type TranslationProviderProps = {
  translations: Translations;
} & PropsWithChildren;

const TranslationContext = createContext<Translations>({});

export function useTranslationContext(): Translations {
  return useContext(TranslationContext);
}

export const TranslationProvider = ({
  children,
}: PropsWithChildren): ReactNode => {
  const loadedTranslations = useAtomValue(translationQueryAtom);

  const actualTranslations = loadedTranslations.isSuccess
    ? loadedTranslations.data
    : {};

  return (
    <TranslationContext.Provider value={actualTranslations}>
      {children}
    </TranslationContext.Provider>
  );
};

export const StaticTranslationProvider = ({
  translations,
  children,
}: TranslationProviderProps): ReactNode => {
  return (
    <TranslationContext.Provider value={translations}>
      {children}
    </TranslationContext.Provider>
  );
};
