import { BaseError, type BaseErrorOptions } from "@sunrise/error";

/**
 * This means that we can't even attempt to refresh the user's session because the user no longer has valid login credentials.
 */
export class RefreshTokenExpiredError extends BaseError {
  constructor(message: string, options?: BaseErrorOptions) {
    super("RefreshTokenExpiredError", message, options);
  }

  /**
   * This will be handled by logging out the user.
   * So we need to let these errors propagate.
   *
   * Services that call a privateApi should not pretend that there is no data or something like that.
   */
  get canSwallow(): boolean {
    return false;
  }

  get errorCode(): "session_expired" {
    return "session_expired";
  }

  /**
   * There's no point in retrying whatever caused this error. If they are missing or expired that's what it is.
   */
  get shouldRetry(): boolean {
    return false;
  }
}
