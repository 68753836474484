import { Language, TranslateFn } from "@sunrise/i18n";
import { Translations, stringFormat } from "translations";

export function createTranslator(
  language: Language,
  translations: Translations,
): TranslateFn {
  return (key: string, args: unknown[] = [], plural = false): string => {
    const value = translations[key.toLowerCase()];

    if (!value) {
      return key;
    }

    const template = plural ? value.translationPlural : value.translation;

    return template
      ? stringFormat.format(template, ...args) ?? template
      : `🌍 ${key}/${language}`;
  };
}
