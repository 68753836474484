import { videoAdsAtom } from "@sunrise/ads";
import {
  type PlayerState,
  selectPlayerState,
  selectShouldShowWhatIsNext,
} from "@sunrise/player";
import { atom } from "jotai";

const NO_PLAYER_CONTROL_STATES: PlayerState[] = ["idle", "error", "stopped"];

export const shouldNotShowPlayerControlsAtom = atom((get) => {
  const playerState = get(selectPlayerState);
  if (NO_PLAYER_CONTROL_STATES.includes(playerState)) {
    return true;
  }

  const showNext = get(selectShouldShowWhatIsNext);

  // When ads are playing out we also should not allow player-controls to be shown.
  return get(videoAdsAtom).isPlaying || showNext;
});
