import type { AxiosInstance } from "axios";
import { secondsToMilliseconds } from "date-fns";
import type { Nullable } from "vitest";

/**
 * Designed so that the middleware knows when it needs to retry, and can communicate back when the retry has completed.
 */
export type RetryFunction = (done: Promise<void>) => Promise<boolean>;

export function manualRetryAxiosInterceptor(
  axios: AxiosInstance,
  awaitRetry: RetryFunction,
  isRetryableError: (url: string | undefined) => boolean,
  getRetryDelayInSeconds?: () => Nullable<number>,
): AxiosInstance {
  axios.interceptors.response.use(
    (response) => {
      // @ts-expect-error I can not figure out how to add a custom attribute in the config. It works. But it's not in the types.
      response.config?.onComplete?.();

      return response;
    },
    async (error) => {
      error.response?.config?.onComplete?.();

      const code = error.response?.status ?? 0;

      // Decide when a request is fatal and needs a manual retry.
      if (code >= 500 && isRetryableError(error.config?.url)) {
        const delayInSeconds = getRetryDelayInSeconds?.() ?? 0;

        const delayPromise = delayInSeconds
          ? new Promise<void>((resolve) => {
              setTimeout(resolve, secondsToMilliseconds(delayInSeconds));
            })
          : undefined;

        // Set up a new promise which will be resolved when the retry has come through.
        let onComplete: (value: void | PromiseLike<void>) => void;
        const done = new Promise<void>((resolve) => {
          onComplete = () => {
            resolve();
          };
        });

        // Wait for the user to confirm the retry.
        return (
          awaitRetry(done)
            // Make sure we wait at least the delay before retrying.
            .then((ok) => {
              if (!ok || !delayPromise) {
                return ok;
              }

              return delayPromise.then(() => true);
            })
            .then((ok) => {
              if (!ok) {
                return Promise.reject(error);
              }

              // Store a callback that is to be called if it succeeded or failed.
              // When it fails, or when it completes, we want to auto-hide.
              return axios.request({ ...error.config, onComplete });
            })
        );
      }

      return Promise.reject(error);
    },
  );

  return axios;
}
