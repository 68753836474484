import { PrivateApiClient, publicApi } from "@sunrise/http-client";
import { Nullable, isNil } from "@sunrise/utils";

import { type AnonymousResponse, type LoginResponse } from "./auth.types";
import { endpoint } from "./endpoints";

export async function fetchRefreshAuthTokens(
  host: string,
  refreshToken: string,
): Promise<LoginResponse> {
  const url = new URL(endpoint.refreshToken(host));

  url.searchParams.set("refresh_token", refreshToken);

  // NOTE: It is SUPER important to use publicApi here. Else the automatic token refresh will kick in on the refresh endpoint itself.
  return (await publicApi.get<LoginResponse>(url.href)).data;
}

export async function invalidateTokens(
  privateApi: PrivateApiClient,
  host: string,
  type: "access_token_only" | "all",
): Promise<void> {
  const url =
    type === "access_token_only"
      ? endpoint.invalidateAccessToken?.(host)
      : endpoint.invalidateAllTokens?.(host);

  if (!url) {
    return;
  }

  await privateApi.get(url);
}

export async function anonymousLogin(
  host: Nullable<string>,
  deviceTypeName: string,
) {
  if (isNil(host)) throw new Error("api host is not defined");

  const { data: user } = await publicApi.post<AnonymousResponse>(
    endpoint.anonymous(host),
  );

  const { data: login } = await publicApi.get<LoginResponse>(
    endpoint.login(host, user.id, deviceTypeName),
  );

  return login;
}
