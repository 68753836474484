export type BaseErrorOptions = {
  errorCode?: string;
  cause?: unknown;
  extras?: Record<string, unknown>;
};

/**
 * A base error type that can be used throughout clients to determine how to further handle the error.
 *
 * NOTE: modern-errors requires us to output as type: module which is not really an option atm?
 * https://github.com/ehmicky/modern-errors/issues/3#issuecomment-1362892142
 */
export class BaseError extends Error {
  public readonly cause: unknown;

  constructor(
    name: string,
    message: string,
    protected readonly opts?: BaseErrorOptions,
  ) {
    super(message);
    this.name = name ?? this.constructor.name;

    if (typeof Error.captureStackTrace === "function") {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(message).stack;
    }

    this.cause = opts?.cause ?? null;
  }

  /**
   * By default we say that errors can not be swallowed.
   *
   * Each new error type can override this behaviour.
   *
   * Swallowing an error means that whatever sees this error can not perform a fallback mechanism itself.
   * It means it needs to let the error flow through instead of pretending that there are no recordings for example.
   */
  get canSwallow(): boolean {
    return false;
  }

  /**
   * must be a function - if this is a getter, it returns "TypeError: no default value"
   */
  toString(): string {
    return this.name;
  }

  /**
   * Tracing means if we should log the error to a tracing mechanism like Sentry or not.
   */
  get shouldTrace(): boolean {
    return true;
  }

  get errorCode(): string | null {
    return this.opts?.errorCode ?? null;
  }

  /**
   * Does it make sense to retry whatever caused this error or not?
   */
  get shouldRetry(): boolean {
    return true;
  }

  get extras(): Record<string, unknown> {
    return {
      cause: this.cause ?? null,
      ...this.opts?.extras,
    };
  }
}
