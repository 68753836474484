import { BaseError } from "@sunrise/error";

import { isShakaError } from "../utils/is-shaka-error";

export class PlayerError extends BaseError {
  constructor(e: unknown) {
    if (isShakaError(e)) {
      super("ShakaPlayerError", e.message, {
        errorCode: e.code.toString(),
        cause: e,
        extras: {
          data: e.data,
        },
      });
    } else {
      super(
        "UnknownPlayerError",
        e instanceof Error ? e.message : "unknown-player-error",
        { cause: e },
      );
    }
  }

  get shouldRetry(): boolean {
    return false;
  }
}
