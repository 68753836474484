import { playerAtom, selectPlayerCurrentPlayRequest } from "@sunrise/player";
import { playerManagerAtom } from "@sunrise/yallo-common-player-manager";
import { atom } from "jotai";

export const showShowPlayOverlay = atom((get) => {
  return (
    get(playerAtom).state === "idle" &&
    !get(selectPlayerCurrentPlayRequest) &&
    !get(playerManagerAtom).playRequest
  );
});
