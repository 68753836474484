import { Nullable } from "@sunrise/utils";

import { PlayerCurrentState, PlayerStateField } from "./player-state-message";

export const SOURCE_ATTRIBUTES: PlayerStateField[] = [
  "channel_id",
  "recording_id",
  "media_id",
  "epg_entry_id",
  "player_content_type",
];

export const LANG_ATTRIBUTES: PlayerStateField[] = [
  "player_audio_language",
  "player_subtitle_language",
];

const CRITICAL_ATTRIBUTES: PlayerStateField[] = [
  ...SOURCE_ATTRIBUTES,
  ...LANG_ATTRIBUTES,
  "player_state",
  "stream_target",
];

export function hasMeaningfulChange(
  previous: Nullable<PlayerCurrentState>,
  next: Nullable<PlayerCurrentState>,
  attributes?: PlayerStateField[],
): boolean {
  return (
    (!previous && !!next) ||
    (attributes ?? CRITICAL_ATTRIBUTES).some(
      (attr) => previous?.[attr] !== next?.[attr],
    )
  );
}
