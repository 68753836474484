import { Atom, atom } from "jotai";
import { atomFamily } from "jotai/utils";
import { Nullable } from "vitest";

import { allChannelsAtom } from "./all-channels.atom";
import { type Channel, type ChannelId } from "./channel.types";
import { getChannelLogo } from "./channel.utils";

/**
 * An atom to get the details of a specific channelId.
 *
 * It is important that we look through all the channels here and not just the channels in the currently selected channel group.
 */
export const channelByIdAtom = atomFamily<
  Nullable<ChannelId>,
  Atom<Promise<Nullable<Channel>>>
>((channelId: Nullable<ChannelId>) => {
  const innerAtom = atom(async (get) => {
    return (await get(allChannelsAtom)).data.result.find(
      (channel) => channel.id === channelId,
    );
  });
  innerAtom.debugLabel = `channelByIdAtom(${channelId})`;
  return innerAtom;
});

export const selectChannelLogo = (
  channel: Nullable<Channel>,
): Nullable<string> => {
  return channel ? getChannelLogo(channel)?.url : null;
};
