import { type Nullable } from "@sunrise/utils";
import { isNil } from "lodash";

import { type DateToNumberConverter } from "../stream.types";

/**
 * The problem is that 'currentTime' is not a Date object, but a number. And this number differs depending on the type of stream it is.
 * If the stream has an offset, then currentTime is the number of seconds from the beginning of the stream. (live)
 * If the stream does not have an offset, then currentTime is the number of seconds since epoch. (replay)
 *
 * For example:
 *  If we have a replay stream with an offset of now - 1h, when we ask what is the number for now - 1h it will return 0. If we ask what is the number for now, it will return 3600. If we ask the date for 3600 we would get back now.
 *  If we have a live stream it has no offset. If we ask for now it would return the amount of seconds since epoch. And the other way around.
 */
export function createDateToCurrentTimeConverter(
  offset: Nullable<number>,
): DateToNumberConverter {
  return {
    fromDate: (date: Date): number => {
      if (isNil(offset)) {
        return date.getTime() / 1000;
      }

      return (date.getTime() - offset) / 1000;
    },
    toDate: (time: number): Nullable<Date> => {
      if (isNil(offset) && time > 0) {
        return new Date(time * 1000);
      }

      if (!isNil(offset)) {
        return new Date(offset + time * 1000);
      }

      return null;
    },
  };
}
