import { deviceInfo } from "@sunrise/utils";

export function getDeviceType():
  | "embedded_player_ios"
  | "embedded_player_android"
  | "embedded_player_macos_safari"
  | "embedded_player" {
  if (deviceInfo.isIOS) {
    return "embedded_player_ios";
  }
  if (deviceInfo.isMobileOrTablet) {
    return "embedded_player_android";
  }
  if (deviceInfo.isSafari) {
    return "embedded_player_macos_safari";
  }

  return "embedded_player";
}
