import { Store } from "@sunrise/store";
import { WebsocketService } from "@sunrise/yallo-websocket";

import { PlayerAnalyticsService } from "./player-analytics.service";

export function initAnalytics(
  store: Store,
  reactToVisibilityChange: boolean,
  ws: WebsocketService,
): void {
  new PlayerAnalyticsService(store, reactToVisibilityChange, (state) =>
    ws.logPlayerState(state),
  );
}
