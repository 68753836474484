import { hostsAtom, httpClientAtom } from "@sunrise/http-client";
import { selectJwtUserToken } from "@sunrise/jwt";
import { type Nullable } from "@sunrise/utils";
import { type ChannelId } from "@sunrise/yallo-channel";
import { type User, type UserActiveChannelReplay } from "@sunrise/yallo-user";
import { atomWithSuspenseQuery } from "jotai-tanstack-query";
import { isNil } from "lodash";

import { ME_ENDPOINT_STALE_TIME_IN_MS } from "./constants";
import { queryKeys } from "./query-keys";

export const userEndpoint = {
  me: (host: string) => `${host}/kamino/users/me`,
};

export const userAtom = atomWithSuspenseQuery<
  Nullable<User>,
  unknown,
  Nullable<User>,
  Nullable<User>,
  ReturnType<typeof queryKeys.user>
>((get) => {
  const hosts = get(hostsAtom);
  const host = hosts.api;
  if (isNil(host)) throw new Error("api host is not defined");

  const privateApi = get(httpClientAtom).privateApi;
  if (isNil(privateApi)) throw new Error("privateApi is not defined");

  return {
    // NOTE: We keep the accessToken in the key because we need the data to reload when the access token changes.
    queryKey: queryKeys.user(get(selectJwtUserToken)),
    queryFn: async () => {
      return (await privateApi.get<User>(userEndpoint.me(host))).data;
    },
    staleTime: ME_ENDPOINT_STALE_TIME_IN_MS, // 5 hours stale time
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  };
});

export function selectStartUpChannelId(state: User): Nullable<ChannelId> {
  return state.startup_channel.channel_id;
}

export function selectActiveReplayChannels(
  state: User,
): Nullable<UserActiveChannelReplay[]> {
  return state.global_client_settings.replay_activated_channels;
}
