import { isSSR } from "@sunrise/utils";
import { atomWithStorage } from "jotai/utils";

import { getBrowserLanguage } from "./get-browser-language";
import { type Language } from "./i18n.types";

/**
 * Stores the current language of the application.
 */
export const currentLanguageAtom = atomWithStorage<Language>(
  "current-language",
  getBrowserLanguage(!isSSR() ? window.navigator.language : "de"), // if window is undefined, means we're running on a server
);
