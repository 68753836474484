import { type Nullable } from "@sunrise/utils";
import { PlayRequest } from "@sunrise/yallo-common-player-manager";
import {
  type Stream,
  createDateToCurrentTimeConverter,
} from "@sunrise/yallo-stream";

/*
 * This module contains store of the player.
 * It's used as a main source of truth by the PlayerAdapter to react to changes.
 */
import areEqual from "fast-deep-equal";
import { Atom, WritableAtom, atom } from "jotai";
import { atomWithReducer, selectAtom } from "jotai/utils";

import {
  DEFAULT_PLAYER_DELAYED_BUFFER_SETTINGS,
  DEFAULT_PLAYER_LIVE_BUFFER_SETTINGS,
} from "./constants";
import {
  FullscreenMode,
  type PlayerAudioTrack,
  type PlayerDelayedBufferSettings,
  type PlayerLiveBufferSettings,
  PlayerState,
  type PlayerSubtitleTrack,
  type TrackId,
} from "./player.types";
import { getStreamModelForPlayRequest } from "./utils/get-stream-model-for-play-request";
import { isLinearPlayRequest } from "./utils/is-linear-play-request";

export const playerLiveBufferSettingsAtom =
  atom<PlayerLiveBufferSettings | null>(DEFAULT_PLAYER_LIVE_BUFFER_SETTINGS);

export const playerDelayedBufferSettingsAtom =
  atom<PlayerDelayedBufferSettings | null>(
    DEFAULT_PLAYER_DELAYED_BUFFER_SETTINGS,
  );

/**
 * The state for the player is pretty big as it contains state that is inter-connected.
 */
export type PlayerAtomState = {
  /**
   * The request associated with the stream that is set on the player (if any).
   * When the stream is cleared, this is also cleared.
   *
   * This tells you what request should be playing in the player atm. So if it contains { channel: '123' } it means we should be playing channel 123 live.
   * Therefore you can look up the EPG info for the channel at this time and you would know what EPG event is playing.
   *
   * If you want to know what is about to be playing then you need to look first at PlayerManagerAtom's playRequest.
   */
  playRequest: Nullable<PlayRequest>;
  /**
   * This is passed to the player when the stream has changed.
   * When true, the player controller will reroute the user to the player page on switch of the stream.
   */
  forceRedirect: boolean;
  /**
   * When this is true, we can force the player to autorecover.
   */
  forceRecovery: boolean;
  /**
   * This is passed to the player when the stream has changed and the initialCurrentTime is set.
   */
  initialCurrentTime: Nullable<number>;
  /**
   * Indicates where the player should be seeking to. To make the player actually seek, you need to set the currentTime.
   * The seekTime just serves as a placeholder in the state on which other atoms can based themselves to derive what would be playing at the seekTime.
   */
  seekTime: Nullable<number>;
  /**
   * When this is a date we need to keep pretending the player actually is at this point in time.
   * It is stored as a Date since the currentTime notation differs between live and replay.
   *
   * It means whenever we ask the player what the current time is, it will pretend it is this time.
   * This is so that the UI will keep pretending the player is at this time until the player has actually seeked to this time.
   *
   * As soon as we seek, we clear this date.
   * We also clear this date when we change streams.
   */
  seekConfirmationLinear: Nullable<Date>;
  /**
   * The current stream that the player is playing.
   */
  stream: Nullable<Stream>;
  /**
   * We keep the date when the stream was set.
   * This is so we can check of the stream was set before the app is foregrounded.
   * Because that would mean the stream is stale and needs to be refreshed.
   */
  streamSetAt: Nullable<Date>;
  /**
   * This can only contain errors that are thrown by the player. For example, a codec is not supported. Issue loading DRM.
   * It can never contain errors that come from the backend or business logic errors.
   *
   * It is possible for the player to throw an error and still end up in a playing state btw.
   * If have seen a 7000 error code. I think it's because DRM did not fully load yet and then shaka will retry things until it works.
   */
  error?: Nullable<unknown>;
  /**
   * This is set to true when the player is cleared out / stopped because of a business rule.
   * That means we should also not attempt to auto-recover the player. Or to auto-start the player.
   */
  isAutoStopped: boolean;
  /**
   * This is set to true when the player is in picture in picture mode.
   */
  shouldBePictureInPicture: boolean;
  /**
   * This shows if the player supports picture in picture.
   */
  pictureInPictureSupported: boolean;
  /**
   * The user has requested the player to be in this state.
   * We should attempt to sync this request by pausing or resuming the player as necessary.
   * If you want to know if the player is actually playing or paused, you need to read out the state.
   */
  shouldBePaused: boolean;
  /**
   * This is updated when the UI determines that the player should be visible or not.
   * NOTE: Could be derived from the location state. Should we pull it from there or have the UI update us manually?
   */
  shouldBeVisible: boolean;
  /**
   * The user has requested the player to be in this state.
   * We should attempt to sync this request by muting or unmuting the player as necessary.
   */
  shouldBeMuted: boolean;
  /**
   * The user has requested the player to be in this state.
   */
  shouldBeFullscreen: boolean;
  /**
   * This is set to "web" if normal fullscreen with "fscreen" is available
   * It's set to "native" when the browser does not support fullscreen and the player itself is in charge
   * Set to "false" if no fullscreen is available
   */
  fullscreenSupportedMode: FullscreenMode;
  /**
   * Do not use this for other than player analytics on websocket, it does not really stop the player
   */
  shouldReportAsStopped: boolean;
  /**
   * The one true state.
   * The player should go from idle -> loading -> loaded -> playing (-> paused -> playing) -> stopped -> idle
   */
  state: PlayerState;
  /**
   * Depending on the type of stream, the meaning here is different.
   * For live streams, this will be the time in seconds since epoch.
   * For replay streams, this will be the time in seconds since the start (timepoint) of the stream.
   * The timepoint of the stream is stored in the stream object.
   * For on-demand streams, this will be the time in seconds since the start of the stream.
   */
  currentTime: Nullable<number>;
  /**
   * It is possible that while seeking, before the content is properly loaded, we ask the player to seek.
   * Let's say ads are playing and during the ads we seeked.
   * Then we want the player to resume playout on the newly desired time.
   */
  desiredCurrentTime: Nullable<number>;
  duration: Nullable<number>;
  audioTracks: Nullable<PlayerAudioTrack[]>;
  subtitleTracks: Nullable<PlayerSubtitleTrack[]>;
  // user preference
  // Here we store attributes of tracks. Like what language we prefer. Or how many channels.
  // This is so we can map these preferences to new tracks when we change streams in the player.
  preferredAudioTrackLang: PlayerAudioTrack["lang"];
  preferredAudioTrackFormat: PlayerAudioTrack["format"];
  preferredSubtitleTrackLang: PlayerSubtitleTrack["lang"];

  currentAudioTrackId: Nullable<TrackId>;
  currentSubtitleTrackId: Nullable<TrackId>;

  /**
   * Buffering state.
   */
  bufferInterruptions: number;
  bufferInterruptionTimeInMs: number;
  bufferInterruptionStartedAt: Nullable<Date>;
};

export function makePlayerAtomDefaultState(
  state?: Partial<PlayerAtomState>,
): PlayerAtomState {
  return {
    currentTime: state?.currentTime ?? null,
    desiredCurrentTime: state?.desiredCurrentTime ?? null,
    seekTime: state?.seekTime ?? null,
    seekConfirmationLinear: state?.seekConfirmationLinear ?? null,
    stream: state?.stream ?? null,
    streamSetAt: state?.streamSetAt ?? null,
    state: state?.state ?? "idle",
    playRequest: state?.playRequest ?? null,
    initialCurrentTime: state?.initialCurrentTime ?? null,
    forceRedirect: state?.forceRedirect ?? true,
    error: state?.error ?? null,
    forceRecovery: state?.forceRecovery ?? false,
    isAutoStopped: state?.isAutoStopped ?? false,
    shouldBePictureInPicture: state?.shouldBePictureInPicture ?? false,
    pictureInPictureSupported: state?.pictureInPictureSupported ?? false,
    shouldBePaused: state?.shouldBePaused ?? false,
    shouldBeMuted: state?.shouldBeMuted ?? false,
    shouldBeVisible: state?.shouldBeVisible ?? true,
    shouldBeFullscreen: state?.shouldBeFullscreen ?? false,
    fullscreenSupportedMode: state?.fullscreenSupportedMode ?? false,
    shouldReportAsStopped: state?.shouldReportAsStopped ?? false,
    duration: state?.duration ?? null,
    audioTracks: state?.audioTracks ?? null,
    subtitleTracks: state?.subtitleTracks ?? null,
    preferredAudioTrackLang: state?.preferredAudioTrackLang ?? null,
    preferredAudioTrackFormat: state?.preferredAudioTrackFormat ?? "Stereo",
    preferredSubtitleTrackLang: state?.preferredSubtitleTrackLang ?? null,
    currentAudioTrackId: state?.currentAudioTrackId ?? null,
    currentSubtitleTrackId: state?.currentSubtitleTrackId ?? null,
    bufferInterruptions: state?.bufferInterruptions ?? 0,
    bufferInterruptionTimeInMs: state?.bufferInterruptionTimeInMs ?? 0,
    bufferInterruptionStartedAt: state?.bufferInterruptionStartedAt ?? null,
  };
}

const DEFAULT_STATE = makePlayerAtomDefaultState();

export function makePlayerAtom(
  state = DEFAULT_STATE,
): WritableAtom<PlayerAtomState, [PlayerAction], void> {
  const internalAtom = atomWithReducer<PlayerAtomState, PlayerAction>(
    state,
    playerAtomReducer,
  );

  // debug label has to be defined because the atom is encapsulated in a factory
  internalAtom.debugLabel = "playerAtom";

  return internalAtom;
}

export const playerAtom = makePlayerAtom();

type ActionSetStream = {
  type: "player/set-stream";
  payload: {
    stream: Stream;
    streamSetAt: Nullable<Date>;
    playRequest: Nullable<PlayRequest>;
    initialCurrentTime: Nullable<number>;
    forceRedirect: boolean;
    loadPaused: boolean;
  };
};

type ActionClearStream = {
  type: "player/clear-stream";
};

type ActionShouldPlay = {
  type: "player/should-play";
};

type ActionSetStatePlaying = {
  type: "player/set-state-playing";
};

type ActionSetStateLoaded = {
  type: "player/set-state-loaded";
};

type ActionSetStatePaused = {
  type: "player/set-state-paused";
};

type ActionSetStateIdle = {
  type: "player/set-state-idle";
};

type ActionSetStateIdleIfStopped = {
  type: "player/set-state-idle-if-stopped";
};

type ActionSetStateStopped = {
  type: "player/set-state-stopped";
};

type ActionShouldPause = {
  type: "player/should-pause";
};

type ActionToggleMute = {
  type: "player/toggle-mute";
};

type ActionToggleFullscreen = {
  type: "player/toggle-fullscreen";
};

type ActionSetFullscreenSupportedMode = {
  type: "player/set-fullscreen-supported-mode";
  payload: {
    supportedMode: FullscreenMode;
  };
};

type ActionTogglePictureInPicture = {
  type: "player/toggle-picture-in-picture";
};

type ActionSetPictureInPictureSupported = {
  type: "player/set-picture-in-picture-supported";
  payload: {
    supported: boolean;
  };
};

type ActionShouldReportAsStopped = {
  type: "player/should-report-as-stopped";
};

type ActionShouldSetCurrentTime = {
  type: "player/set-current-time";
  payload: {
    currentTime: number;
  };
};

type ActionSetDesiredCurrentTime = {
  type: "player/set-desired-current-time";
  payload: {
    desiredCurrentTime: Nullable<number>;
  };
};

type ActionShouldSetSeekTime = {
  type: "player/set-seek-time";
  payload: {
    seekTime: number;
  };
};

type ActionShouldClearSeekTime = {
  type: "player/clear-seek-time";
};

type ActionConfirmSeekTime = {
  type: "player/confirm-seek-time";
  payload: {
    date: Date;
  };
};

type ActionPlayerSeeked = {
  type: "player/seeked";
};

type ActionPlayerForceRecovery = {
  type: "player/force-recovery";
};

type ActionSetError = {
  type: "player/set-error";
  payload: {
    error: unknown;
  };
};

type ActionToggleShouldPlayPause = {
  type: "player/toggle-should-play-pause";
};

type ActionSetIsVisible = {
  type: "player/set-is-visible";
  payload: {
    isVisible: boolean;
  };
};

type ActionShouldSetDuration = {
  type: "player/set-duration";
  payload: {
    duration: number;
  };
};

type ActionClearDuration = {
  type: "player/clear-duration";
};

type ActionPlayerReset = {
  type: "player/reset";
};

type ActionPlayerAutoStop = {
  type: "player/auto-stop";
};

type ActionSetSubtitlesAndAudio = {
  type: "player/set-subtitles-and-audio";
  payload: {
    audioTracks: Nullable<PlayerAudioTrack[]>;
    subtitleTracks: Nullable<PlayerSubtitleTrack[]>;
    audioTrackId: Nullable<TrackId>;
    subtitleTrackId: Nullable<TrackId>;
  };
};

type ActionSelectAudio = {
  type: "player/select-audio";
  payload: {
    audio: Nullable<TrackId>;
  };
};

type ActionSelectSubtitles = {
  type: "player/select-subtitles";
  payload: {
    subtitle: Nullable<TrackId>;
  };
};

type ActionBufferInterruptionStart = {
  type: "player/buffer-interruption-start";
};

type ActionBufferInterruptionEnd = {
  type: "player/buffer-interruption-end";
};

export type PlayerAction =
  | ActionBufferInterruptionEnd
  | ActionBufferInterruptionStart
  | ActionSetStream
  | ActionSetError
  | ActionClearStream
  | ActionConfirmSeekTime
  | ActionPlayerSeeked
  | ActionShouldPlay
  | ActionShouldPause
  | ActionToggleMute
  | ActionToggleFullscreen
  | ActionSetFullscreenSupportedMode
  | ActionTogglePictureInPicture
  | ActionSetPictureInPictureSupported
  | ActionShouldReportAsStopped
  | ActionToggleShouldPlayPause
  | ActionShouldSetCurrentTime
  | ActionSetDesiredCurrentTime
  | ActionShouldSetSeekTime
  | ActionShouldClearSeekTime
  | ActionSetIsVisible
  | ActionSetStatePaused
  | ActionSetStatePlaying
  | ActionSetStateLoaded
  | ActionSetStateIdle
  | ActionSetStateIdleIfStopped
  | ActionSetStateStopped
  | ActionShouldSetDuration
  | ActionClearDuration
  | ActionPlayerReset
  | ActionPlayerForceRecovery
  | ActionPlayerAutoStop
  | ActionSetSubtitlesAndAudio
  | ActionSelectAudio
  | ActionSelectSubtitles;

// 1.653 weeks 3 days 1 hour 46 min 40 s
const UNLIKELY_HIGH_REPLAY_CURRENT_TIME_THAT_IS_LOWER_THAN_LIVE = 1_000_000_000;

export function playerAtomReducer(
  ps: PlayerAtomState,
  action: PlayerAction,
): PlayerAtomState {
  switch (action.type) {
    case "player/set-current-time": {
      // When we are loading we should not be accepting updates to the currentTime.
      // The player would only set it when it is loaded. And when we set it we should be setting in the loading of the stream.
      if (!ps.playRequest || ps.state === "loading") return ps;

      // If the stream is live and the currentTime is less than the cutoff, then we reject the currentTime.
      // The currentTime must have been set by a debounced function in the player or perhaps the player is not yet up to date with the new playRequest.
      if (
        ps.playRequest.type === "live" &&
        action.payload.currentTime <
          UNLIKELY_HIGH_REPLAY_CURRENT_TIME_THAT_IS_LOWER_THAN_LIVE
      ) {
        return ps;
      }

      return {
        ...ps,
        currentTime: action.payload.currentTime,
      };
    }
    case "player/set-desired-current-time": {
      return { ...ps, desiredCurrentTime: action.payload.desiredCurrentTime };
    }
    case "player/set-seek-time": {
      return {
        ...ps,
        seekTime: action.payload.seekTime,
        seekConfirmationLinear: null,
      };
    }
    case "player/clear-seek-time": {
      return {
        ...ps,
        seekTime: null,
      };
    }
    case "player/confirm-seek-time": {
      return {
        ...ps,
        seekConfirmationLinear: action.payload.date,
      };
    }
    case "player/seeked": {
      return {
        ...ps,
        seekConfirmationLinear: null,
      };
    }
    case "player/set-stream": {
      let newCurrentTime: Nullable<number> = null;

      if (
        isLinearPlayRequest(action.payload.playRequest) &&
        action.payload.playRequest.type === "replay"
      ) {
        // When we switch to a replay stream, set the current time to the initial current time.
        newCurrentTime = action.payload.initialCurrentTime ?? null;
      } else if (action.payload.playRequest?.type === "live") {
        // When switching to live we just need to set the currentTime to "now".
        // There's a small issue here where now may actually not be now now if we are faking the timings.
        // We do not have access to the time atom here.
        const converter = createDateToCurrentTimeConverter(null);
        newCurrentTime = converter.fromDate(new Date());
      }

      const changedPlayRequestType =
        ps.playRequest?.type !== action.payload.playRequest?.type;

      return {
        ...ps,
        forceRecovery: false,
        // We want to make sure that whenever we change stream, we attempt to play it again.
        // We don't need to stay paused when we change streams.
        shouldBePaused: action.payload.loadPaused,
        // We also need to reset the seekTime when we switch requests. Because the seekTime can be incorrect.
        seekTime: changedPlayRequestType ? null : ps.seekTime,
        stream: action.payload.stream,
        streamSetAt: action.payload.streamSetAt ?? new Date(),
        playRequest: action.payload.playRequest,
        initialCurrentTime: action.payload.initialCurrentTime,
        forceRedirect: action.payload.forceRedirect,
        isAutoStopped: false,
        state: "loading",
        error: null,
        currentTime: newCurrentTime,
        bufferInterruptionStartedAt: null,
        bufferInterruptions: 0,
        bufferInterruptionTimeInMs: 0,
        desiredCurrentTime: null,
      };
    }
    case "player/set-error": {
      return {
        ...ps,
        state: "error",
        error: action.payload.error,
        currentTime: null,
        seekTime: null,
        seekConfirmationLinear: null,
        desiredCurrentTime: null,
      };
    }
    case "player/clear-stream": {
      return {
        ...ps,
        state: "idle",
        stream: null,
        streamSetAt: null,
        isAutoStopped: false,
        forceRedirect: true,
        playRequest: null,
        initialCurrentTime: null,
        currentTime: null,
        bufferInterruptionStartedAt: null,
        bufferInterruptions: 0,
        bufferInterruptionTimeInMs: 0,
        desiredCurrentTime: null,
      };
    }
    case "player/should-play": {
      return {
        ...ps,
        shouldBePaused: false,
        shouldReportAsStopped: false,
      };
    }
    case "player/should-pause": {
      return {
        ...ps,
        shouldBePaused: true,
        shouldReportAsStopped: false,
      };
    }
    case "player/toggle-mute": {
      return {
        ...ps,
        shouldBeMuted: !ps.shouldBeMuted,
      };
    }
    case "player/toggle-fullscreen": {
      return {
        ...ps,
        shouldBeFullscreen: !ps.shouldBeFullscreen,
      };
    }
    case "player/set-fullscreen-supported-mode": {
      return {
        ...ps,
        fullscreenSupportedMode: action.payload.supportedMode,
      };
    }
    case "player/toggle-picture-in-picture": {
      return {
        ...ps,
        shouldBePictureInPicture: !ps.shouldBePictureInPicture,
      };
    }
    case "player/set-picture-in-picture-supported": {
      return {
        ...ps,
        pictureInPictureSupported: action.payload.supported,
      };
    }
    case "player/should-report-as-stopped": {
      return {
        ...ps,
        shouldBePaused: true,
        shouldReportAsStopped: true,
      };
    }
    case "player/toggle-should-play-pause": {
      return {
        ...ps,
        shouldBePaused: !ps.shouldBePaused,
        shouldReportAsStopped: false,
      };
    }
    case "player/set-is-visible": {
      return {
        ...ps,
        shouldBeVisible: action.payload.isVisible,
      };
    }
    case "player/set-state-playing": {
      return {
        ...ps,
        state: "playing",
      };
    }
    case "player/set-state-paused": {
      return {
        ...ps,
        state: "paused",
      };
    }
    case "player/set-state-idle": {
      return {
        ...ps,
        state: "idle",
      };
    }
    case "player/set-state-stopped": {
      return {
        ...ps,
        // We also want to reset the stream to unload the player.
        stream: null,
        streamSetAt: null,
        state: "stopped",
      };
    }
    case "player/auto-stop": {
      return {
        ...ps,
        // We also want to reset the stream to unload the player.
        stream: null,
        streamSetAt: null,
        isAutoStopped: true,
        // We are not setting it to stopped.
        state: "idle",
      };
    }
    case "player/set-state-loaded": {
      // NOTE: only set state to loaded if player is not playing in case loaded is fired twice by the player
      if (ps.state !== "playing") {
        return {
          ...ps,
          state: "loaded",
          seekTime: null,
          seekConfirmationLinear: null,
        };
      }

      return ps;
    }
    case "player/set-state-idle-if-stopped": {
      if (ps.state === "stopped") {
        return {
          ...ps,
          state: "idle",
          playRequest: null,
          stream: null,
          streamSetAt: null,
          initialCurrentTime: null,
          currentTime: null,
        };
      }

      return ps;
    }
    case "player/set-duration": {
      if (!ps.playRequest || ps.playRequest.type === "live") return ps;

      return {
        ...ps,
        duration: action.payload.duration,
      };
    }
    case "player/clear-duration": {
      return {
        ...ps,
        duration: null,
      };
    }
    case "player/reset": {
      return DEFAULT_STATE;
    }
    case "player/set-subtitles-and-audio": {
      return {
        ...ps,
        audioTracks: action.payload.audioTracks,
        subtitleTracks: action.payload.subtitleTracks,
        currentAudioTrackId: action.payload.audioTrackId,
        currentSubtitleTrackId: action.payload.subtitleTrackId,
      };
    }
    case "player/select-audio": {
      const current = ps.audioTracks?.find(
        (t) => t.id === action.payload.audio,
      );
      if (!current) return ps;

      return {
        ...ps,
        preferredAudioTrackLang: current.lang,
        preferredAudioTrackFormat:
          current.channelsCount === 2 ? "Stereo" : "Dolby",
        currentAudioTrackId: current.id,
      };
    }
    case "player/select-subtitles": {
      if (action.payload.subtitle === null) {
        return {
          ...ps,
          currentSubtitleTrackId: null,
          preferredSubtitleTrackLang: null,
        };
      }

      const current = ps.subtitleTracks?.find(
        (t) => t.id === action.payload.subtitle,
      );
      if (!current) return ps;

      return {
        ...ps,
        preferredSubtitleTrackLang: current.lang,
        currentSubtitleTrackId: current.id,
      };
    }
    case "player/buffer-interruption-start": {
      // Guard against double buffer starts.
      if (ps.bufferInterruptionStartedAt) {
        return ps;
      }

      // TODO: In the future, we can remember a piece in the state to determine if the interruption happened because of user interaction or not.
      return {
        ...ps,
        bufferInterruptionStartedAt: new Date(),
      };
    }
    case "player/buffer-interruption-end": {
      if (!ps.bufferInterruptionStartedAt) return ps;

      return {
        ...ps,
        bufferInterruptionStartedAt: null,
        bufferInterruptions: ps.bufferInterruptions + 1,
        bufferInterruptionTimeInMs:
          ps.bufferInterruptionTimeInMs +
          (new Date().getTime() - ps.bufferInterruptionStartedAt.getTime()),
      };
    }
    case "player/force-recovery": {
      return {
        ...ps,
        forceRecovery: true,
      };
    }
  }
}

/*
 *
 * ACTIONS
 *
 */

export function actionPlayerSetCurrentTime(
  currentTime: number,
): ActionShouldSetCurrentTime {
  return {
    type: "player/set-current-time",
    payload: {
      currentTime,
    },
  };
}

export function actionPlayerSetStream(
  stream: Stream,
  playRequest: Nullable<PlayRequest> = null,
  initialCurrentTime: Nullable<number> = null,
  forceRedirect = true,
  loadPaused = false,
  at = new Date(),
): ActionSetStream {
  return {
    type: "player/set-stream",
    payload: {
      stream,
      streamSetAt: at,
      playRequest,
      initialCurrentTime,
      forceRedirect,
      loadPaused,
    },
  };
}

export function actionPlayerSetError(error: unknown): ActionSetError {
  return {
    type: "player/set-error",
    payload: {
      error,
    },
  };
}

export function actionPlayerForceRecovery(): ActionPlayerForceRecovery {
  return {
    type: "player/force-recovery",
  };
}

export function actionPlayerSetPlaying(): ActionSetStatePlaying {
  return {
    type: "player/set-state-playing",
  };
}

export function actionPlayerSetLoaded(): ActionSetStateLoaded {
  return {
    type: "player/set-state-loaded",
  };
}

export function actionPlayerSetPaused(): ActionSetStatePaused {
  return {
    type: "player/set-state-paused",
  };
}

export function actionPlayerSetIdle(): ActionSetStateIdle {
  return {
    type: "player/set-state-idle",
  };
}

export function actionPlayerSetIdleIfStopped(): ActionSetStateIdleIfStopped {
  return {
    type: "player/set-state-idle-if-stopped",
  };
}

export function actionPlayerSetStopped(): ActionSetStateStopped {
  return {
    type: "player/set-state-stopped",
  };
}

export function actionPlayerAutoStop(): ActionPlayerAutoStop {
  return {
    type: "player/auto-stop",
  };
}

export function actionPlayerClearStream(): ActionClearStream {
  return {
    type: "player/clear-stream",
  };
}

export function actionPlayerShouldPlay(): ActionShouldPlay {
  return {
    type: "player/should-play",
  };
}

export function actionPlayerToggleShouldPlayPause(): ActionToggleShouldPlayPause {
  return {
    type: "player/toggle-should-play-pause",
  };
}

export function actionPlayerShouldPause(): ActionShouldPause {
  return {
    type: "player/should-pause",
  };
}

export function actionPlayerToggleMute(): ActionToggleMute {
  return {
    type: "player/toggle-mute",
  };
}

export function actionPlayerToggleFullscreen(): ActionToggleFullscreen {
  return {
    type: "player/toggle-fullscreen",
  };
}

export function actionPlayerSetFullscreenSupportedMode(
  supportedMode: FullscreenMode,
): ActionSetFullscreenSupportedMode {
  return {
    type: "player/set-fullscreen-supported-mode",
    payload: {
      supportedMode,
    },
  };
}

export function actionPlayerTogglePictureInPicture(): ActionTogglePictureInPicture {
  return {
    type: "player/toggle-picture-in-picture",
  };
}

export function actionPlayerSetPictureInPictureSupported(
  supported: boolean,
): ActionSetPictureInPictureSupported {
  return {
    type: "player/set-picture-in-picture-supported",
    payload: {
      supported,
    },
  };
}

// NOTE: Do not use this for other than player analytics on websocket, it does not really stop the player
export function actionPlayerShouldReportAsStopped(): ActionShouldReportAsStopped {
  return {
    type: "player/should-report-as-stopped",
  };
}

export function actionPlayerIsVisible(isVisible: boolean): ActionSetIsVisible {
  return {
    type: "player/set-is-visible",
    payload: {
      isVisible,
    },
  };
}

export function actionPlayerSetSeekTime(
  seekTime: number,
): ActionShouldSetSeekTime {
  return {
    type: "player/set-seek-time",
    payload: {
      seekTime,
    },
  };
}

export function actionPlayerClearSeekTime(): ActionShouldClearSeekTime {
  return {
    type: "player/clear-seek-time",
  };
}

export function actionPlayerConfirmLinearSeekTime(
  date: Date,
): ActionConfirmSeekTime {
  return {
    type: "player/confirm-seek-time",
    payload: {
      date,
    },
  };
}

export function actionPlayerSeeked(): ActionPlayerSeeked {
  return {
    type: "player/seeked",
  };
}

export function actionPlayerSetDuration(
  duration: number,
): ActionShouldSetDuration {
  return {
    type: "player/set-duration",
    payload: {
      duration,
    },
  };
}

export function actionPlayerClearDuration(): ActionClearDuration {
  return {
    type: "player/clear-duration",
  };
}

export function actionPlayerReset(): ActionPlayerReset {
  return {
    type: "player/reset",
  };
}

export function actionPlayerSetSubtitlesAndAudio({
  audioTracks,
  subtitleTracks,
  audioTrackId,
  subtitleTrackId,
}: {
  audioTracks: Nullable<PlayerAudioTrack[]>;
  subtitleTracks: Nullable<PlayerSubtitleTrack[]>;
  audioTrackId: Nullable<TrackId>;
  subtitleTrackId: Nullable<TrackId>;
}): ActionSetSubtitlesAndAudio {
  return {
    type: "player/set-subtitles-and-audio",
    payload: {
      audioTracks,
      subtitleTracks,
      audioTrackId,
      subtitleTrackId,
    },
  };
}

export function actionPlayerSelectAudio(
  audio: Nullable<TrackId>,
): ActionSelectAudio {
  return {
    type: "player/select-audio",
    payload: {
      audio,
    },
  };
}

export function actionPlayerSelectSubtitles(
  subtitle: Nullable<TrackId>,
): ActionSelectSubtitles {
  return {
    type: "player/select-subtitles",
    payload: {
      subtitle,
    },
  };
}

export function actionPlayerBufferInterruptionStart(): ActionBufferInterruptionStart {
  return {
    type: "player/buffer-interruption-start",
  };
}

export function actionPlayerBufferInterruptionEnd(): ActionBufferInterruptionEnd {
  return {
    type: "player/buffer-interruption-end",
  };
}

export function actionPlayerSetDesiredCurrentTime(
  time: Nullable<number>,
): ActionSetDesiredCurrentTime {
  return {
    type: "player/set-desired-current-time",
    payload: {
      desiredCurrentTime: time,
    },
  };
}

/*
 *
 * SELECTORS
 *
 */

export function selectPlayerCurrentStream(
  currentAtom = playerAtom,
): Atom<PlayerAtomState["stream"]> {
  const slice = selectAtom(currentAtom, (state) => state.stream, areEqual);

  slice.debugLabel = selectPlayerCurrentStream.name;

  return slice;
}

export const selectPlayerDesiredCurrentTime = selectAtom(
  playerAtom,
  (state) => state.desiredCurrentTime,
);

export const playerCurrentStreamAtom = atom((get) =>
  get(selectPlayerCurrentStream(playerAtom)),
);

export const selectPlayerShouldBePlaying = selectAtom(
  playerAtom,
  (state) => state.shouldBePaused === false,
);

// TODO: don't use selectPlayerShouldBePaused() in react components because it will rerender infinitely
export function selectPlayerShouldBePaused(
  currentAtom = playerAtom,
): Atom<boolean> {
  const slice = selectAtom(currentAtom, (state) => state.shouldBePaused);

  slice.debugLabel = selectPlayerShouldBePaused.name;

  return slice;
}

// NOTE: Do not use this for other than player analytics on websocket, it does not really stop the player
export function selectPlayerShouldReportAsStopped(
  currentAtom = playerAtom,
): Atom<boolean> {
  const slice = selectAtom(currentAtom, (state) => state.shouldReportAsStopped);

  slice.debugLabel = selectPlayerShouldReportAsStopped.name;

  return slice;
}

export const selectPlayerCurrentTime = selectAtom(
  playerAtom,
  (state) => state.currentTime,
);

/**
 * To determine if we are lying about the actual current time in the player for linear content.
 */
export const selectPlayerInLinearSeekConfirmation = selectAtom(
  playerAtom,
  (state) => state.seekConfirmationLinear,
);

export const selectPlayerCurrentSeekTime = selectAtom(
  playerAtom,
  (state) => state.seekTime,
);

/**
 * This returns the stream model that is applicable for the current PlayRequest.
 */
export const selectPlayerCurrentStreamModel = selectAtom(playerAtom, (state) =>
  state.playRequest
    ? getStreamModelForPlayRequest(state.playRequest.type)
    : null,
);

/**
 * This returns the player's seekTime or the player's current time if no seekTime is set.
 * It's to be used by the player controls to display the correct EPG information while we are seeking through the content.
 *
 * This will always return a number. It is up to the higher levels to determine what this number means.
 */
export const selectPlayerCurrentTimeIncludingSeekTime = selectAtom(
  playerAtom,
  (state) => {
    return state.seekTime ?? state.currentTime;
  },
);

/**
 * Returns the raw duration reported by the player.
 */
export function selectPlayerCurrentStreamDuration(
  currentAtom = playerAtom,
): Atom<Nullable<number>> {
  const slice = selectAtom(currentAtom, (state) => state.duration);
  slice.debugLabel = selectPlayerCurrentStreamDuration.name;
  return slice;
}
export const selectPlayerState = selectAtom(playerAtom, (data) => data.state);

export const selectPlayerCurrentPlayRequest = selectAtom(
  playerAtom,
  (data) => data.playRequest,
  areEqual,
);

export const selectPlayerIsStopped = selectAtom(
  playerAtom,
  (state) => state.state === "stopped",
);

export const selectPlayerIsAutoStopped = selectAtom(
  playerAtom,
  (state) => state.isAutoStopped && state.state === "idle",
);

export const selectPlayerIsPlaying = selectAtom(
  playerAtom,
  (state) => state.state === "playing",
);

export const selectShouldShowWhatIsNext = selectPlayerIsStopped;

export const selectPlayerIsLoading = selectAtom(
  playerAtom,
  (state) => state.state === "loading",
);

export const selectPlayerSubtitlesAndAudioSettings = selectAtom(
  playerAtom,
  (state) => {
    return {
      audioTracks: state.audioTracks,
      subtitleTracks: state.subtitleTracks,
    };
  },
);

export const selectPlayerCurrentSubtitleTrack = selectAtom(
  playerAtom,
  (state) =>
    state.subtitleTracks?.find((t) => t.id === state.currentSubtitleTrackId) ??
    null,
);

export const selectPlayerCurrentAudioTrackId = selectAtom(
  playerAtom,
  (state) => state.currentAudioTrackId ?? null,
);

export const selectPlayerCurrentSubtitleTrackId = selectAtom(
  playerAtom,
  (state) => state.currentSubtitleTrackId ?? null,
);

export const selectPlayerCurrentAudioTrack = selectAtom(
  playerAtom,
  (state) =>
    state.audioTracks?.find((t) => t.id === state.currentAudioTrackId) ?? null,
);

export const selectPlayerShouldBeMuted = selectAtom(
  playerAtom,
  (state) => state.shouldBeMuted,
);

export const selectPlayerShouldBePictureInPicture = selectAtom(
  playerAtom,
  (state) => state.shouldBePictureInPicture,
);

export const selectPlayerPictureInPictureSupported = selectAtom(
  playerAtom,
  (state) => state.pictureInPictureSupported,
);

export const selectPlayerShouldBeFullscreen = selectAtom(
  playerAtom,
  (state) => state.shouldBeFullscreen,
);

export const selectPlayerFullscreenSupportedMode = selectAtom(
  playerAtom,
  (state) => state.fullscreenSupportedMode,
);

export const selectPlayerStreamSetAt = selectAtom(
  playerAtom,
  (state) => state.streamSetAt,
);

export const selectPlayerCurrentError = selectAtom(
  playerAtom,
  (state) => state.error,
);

export const selectPlayerForcedToRecover = selectAtom(
  playerAtom,
  (state) => state.forceRecovery,
);
