import { DEFAULT_LANGUAGE, languages } from "./constants";
import { Language } from "./i18n.types";

export function getBrowserLanguage(language: string): Language {
  const userLanguage = language.split("-")[0] as Language | undefined;

  if (userLanguage && languages.includes(userLanguage)) {
    return userLanguage;
  }

  return DEFAULT_LANGUAGE as Language;
}
