import { isWithinInterval } from "date-fns";

import { ReplayWindow, getReplayWindow } from "./get-replay-window";

/**
 * An item is in the replay window as long as the start is within the window.
 * @param start
 * @param now
 * @returns
 */
export function isInReplayWindow(
  start: Date,
  now: Date,
): { result: boolean; window: ReplayWindow } {
  const window = getReplayWindow(now);

  return {
    window,
    result: isWithinInterval(start, window),
  };
}
