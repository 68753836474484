import { type UserToken, queryKeys as jwtQueryKeys } from "@sunrise/jwt";
import type { Nullable } from "@sunrise/utils";

import type { RecordingGroupId } from "./recordings.types";

const recordingsBase = (at: Nullable<UserToken>) =>
  [...jwtQueryKeys.userToken(at), "recordings"] as const;

/**
 * When knowing the latest state relies on polling for the data use this as the base key.
 */
const recordingsPullBase = (at: Nullable<UserToken>) =>
  [...recordingsBase(at), "pull"] as const;

/**
 * We initially do a pull but then the state is pushed to us through the websocket.
 * So we should not flush it on the same interval as the "pull-based" recordings.
 */
const recordingsPushBase = (at: Nullable<UserToken>) =>
  [...recordingsBase(at), "push"] as const;

export const queryKeys = {
  recordingsBase,
  recordingsPullBase,
  recordingStats: (at: Nullable<UserToken>) =>
    [...recordingsPushBase(at), "recording-stats"] as const,
  recordingByRecordingId: (at: Nullable<UserToken>, recordingId: string) =>
    [...recordingsPullBase(at), "recordings-per-id", recordingId] as const,
  recordingsStatus: (at: Nullable<UserToken>) =>
    [...recordingsPushBase(at), "recordings-status"] as const,
  recordingsOverview: (at: Nullable<UserToken>) =>
    [...recordingsPullBase(at), "recordings-overview"] as const,
  haveRecordingSchedules: (at: Nullable<UserToken>, assetId: string) =>
    [...recordingsPullBase(at), "have-recording-schedules", assetId] as const,
  recordingGroupItems: (at: Nullable<UserToken>, assetId: string) =>
    [...recordingsPullBase(at), "recording-group-items", assetId] as const,
  recordingItemsByAssetId: (at: Nullable<UserToken>, assetId: string) =>
    [
      ...recordingsPullBase(at),
      "recording-items-by-asset-id",
      assetId,
    ] as const,
  // TODO: doublecheck if that is still used ...
  haveRecordings: (at: Nullable<UserToken>, assetId: string) =>
    [...recordingsPullBase(at), "have-recordings", assetId] as const,
  recordingGroupsByRecordingGroupId: (
    at: Nullable<UserToken>,
    recordingGroupId: RecordingGroupId,
  ) =>
    [
      ...recordingsPullBase(at),
      "recording-groups-by-recording-group-id",
      recordingGroupId,
    ] as const,
};
