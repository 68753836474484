import { BaseError } from "./base-error";

/**
 * Returns true when we should not attempt to recover from this error wherever it is thrown.
 *
 * It means that for example, when we catch an MissingTokensError when we are loading our recordings,
 * we are not supposed to pretend there are no recordings.
 *
 * Instead, we need the error flow through to whatever path knows how to recover from it.
 * In the case of MissingTokensError it should be caught in the ModalErrorBoundary.
 */
export function canSwallowError(
  error: unknown,
  fallback: boolean = false,
): boolean {
  return error instanceof BaseError ? error.canSwallow : fallback;
}
