import {
  EPGEntry,
  getEpgEntryPlayingAtTimeOnChannel,
} from "@sunrise/yallo-epg";
import { atom } from "jotai";
import { selectAtom } from "jotai/utils";

import { playerAtom } from "./player.atom";
import { selectPlayerCurrentDateTimeShownPerMinute } from "./select-player-current-date-time-shown-per-minute";

const seekChannelIdAtom = selectAtom(
  playerAtom,
  (v) => v.playRequest?.channelId,
);

/**
 * Returns the EPG item that is currently playing inside the player.
 * Only works for EPG-based streams.
 *
 * This does take seeking into account.
 */
export const playerCurrentEpgItemShownAtom = atom<Promise<EPGEntry | null>>(
  async (get) => {
    const currentTime = get(selectPlayerCurrentDateTimeShownPerMinute);
    const seekChannelId = get(seekChannelIdAtom);

    if (!currentTime || !seekChannelId) return null;

    return get(getEpgEntryPlayingAtTimeOnChannel({ channelId: seekChannelId }))(
      currentTime,
    );
  },
);
