import { type LoadOptions } from "@sunrise/player-manager";
import { type Nullable } from "@sunrise/utils";
import { type ChannelId } from "@sunrise/yallo-channel";
import { type EPGEntryId } from "@sunrise/yallo-epg";
import { type RecordingId } from "@sunrise/yallo-recordings";
import { atomWithReducer } from "jotai/utils";

import { type PlayRequest } from "./yallo-common-player-manager.types";

type PlayerManagerAtomState = {
  /**
   * We need to refer to this to know which content is loading. For zapping purposes we want to instantly confirm that we are switching the channel.
   * But if we want to know exactly what we are playing in the player we need to look at the playRequest in the player.
   */
  playRequest: Nullable<PlayRequest>;
  /**
   * We need to store the loadOptions associated with the playRequest.
   * Some if it we need to eventually pass to the player after the loading of the stream is done.
   */
  loadOptions: Nullable<LoadOptions>;
  error: Nullable<Error>;
};

export function makePlayerManagerAtomDefaultState(
  state?: Partial<PlayerManagerAtomState>,
): PlayerManagerAtomState {
  return {
    playRequest: state?.playRequest ?? null,
    loadOptions: state?.loadOptions ?? null,
    error: state?.error ?? null,
  };
}

type ActionPlayLiveChannel = {
  type: "player-manager/play-live-channel";
  payload: {
    channelId: ChannelId;
    origin?: "autostart" | "autorecover";
    startPaused?: boolean;
  };
};

type ActionPlayReplay = {
  type: "player-manager/play-replay";
  payload: {
    channelId: ChannelId;
    epgId: EPGEntryId;
    startTime: Date;
    isAtStart?: boolean;
    startPaused?: boolean;
  };
};

type ActionLoadPlayRequest = {
  type: "player-manager/load-play-request";
  payload: {
    playRequest: PlayRequest;
    loadOptions: Nullable<LoadOptions>;
  };
};

type ActionPlayRecording = {
  type: "player-manager/play-recording";
  payload: {
    recordingId: RecordingId;
    channelId: ChannelId;
    startAtSeconds?: number;
    startPaused?: boolean;
  };
};

type ActionReset = {
  type: "player-manager/reset";
};

type ActionSetError = {
  type: "player-manager/set-error";
  payload: {
    error: Error;
  };
};

type PlayerManagerAction =
  | ActionPlayLiveChannel
  | ActionPlayReplay
  | ActionLoadPlayRequest
  | ActionReset
  | ActionSetError
  | ActionPlayRecording;

export const playerManagerAtom = atomWithReducer<
  PlayerManagerAtomState,
  PlayerManagerAction
>(makePlayerManagerAtomDefaultState(), playerManagerAtomReducer);

export function playerManagerAtomReducer(
  ps: PlayerManagerAtomState,
  action: PlayerManagerAction,
): PlayerManagerAtomState {
  switch (action.type) {
    case "player-manager/play-live-channel":
      return {
        playRequest: {
          channelId: action.payload.channelId,
          type: "live",
        },
        loadOptions:
          action.payload.origin || action.payload.startPaused
            ? {
                originatingAction: action.payload.origin,
                ensurePaused: action.payload.startPaused,
              }
            : null,
        error: null,
      };
    case "player-manager/play-replay":
      return {
        playRequest: {
          channelId: action.payload.channelId,
          type: "replay",
          epgId: action.payload.epgId,
          startTime: action.payload.startTime,
        },
        // When we play from the start we want to indicate that to the player.
        // Because it will trigger additional advertising.
        loadOptions:
          action.payload.isAtStart || action.payload.startPaused
            ? {
                originatingAction: "play-from-start",
                ensurePaused: action.payload.startPaused,
              }
            : null,
        error: null,
      };
    case "player-manager/play-recording":
      return {
        playRequest: {
          type: "recording",
          recordingId: action.payload.recordingId,
          channelId: action.payload.channelId,
          startAtSeconds: action.payload.startAtSeconds,
        },
        loadOptions: action.payload.startPaused
          ? { ensurePaused: action.payload.startPaused }
          : null,
        error: null,
      };
    case "player-manager/load-play-request":
      return {
        playRequest: action.payload.playRequest,
        loadOptions: action.payload.loadOptions,
        error: null,
      };
    case "player-manager/reset":
      return makePlayerManagerAtomDefaultState();
    case "player-manager/set-error":
      return makePlayerManagerAtomDefaultState({
        error: action.payload.error,
        // NOTE: It's important we keep the associated playRequest when we error. Because features like zapping rely on knowing which channel was intended to play out.
        //       If it can't play out it still needs to know the last playout attempt.
        playRequest: ps.playRequest,
      });
    default:
      return ps;
  }
}

/*
 *
 * ACTIONS
 *
 */

export function actionPlayerManagerPlayLiveChannelId(
  channelId: ChannelId,
  origin?: "autostart" | "autorecover",
  startPaused?: boolean,
): ActionPlayLiveChannel {
  return {
    type: "player-manager/play-live-channel",
    payload: {
      channelId,
      origin,
      startPaused,
    },
  };
}

export function actionPlayerManagerPlayReplay(
  epgId: EPGEntryId,
  channelId: ChannelId,
  startTime: Date,
  isAtStart?: boolean,
  startPaused?: boolean,
): ActionPlayReplay {
  return {
    type: "player-manager/play-replay",
    payload: {
      epgId,
      channelId,
      startTime,
      isAtStart,
      startPaused,
    },
  };
}

export function actionPlayerManagerPlayRecording(
  recordingId: RecordingId,
  channelId: ChannelId,
  startAtSeconds?: number,
  startPaused?: boolean,
): ActionPlayRecording {
  return {
    type: "player-manager/play-recording",
    payload: {
      recordingId,
      channelId,
      startAtSeconds,
      startPaused,
    },
  };
}

/**
 * When the PlayerManager delegated everything for the incoming PlayRequest, we can reset the state.
 */
export function actionPlayerManagerReset(): ActionReset {
  return {
    type: "player-manager/reset",
  };
}

export function actionPlayerManagerSetError(error: Error): ActionSetError {
  return {
    type: "player-manager/set-error",
    payload: {
      error,
    },
  };
}

export function actionPlayerManagerLoadPlayRequest(
  playRequest: PlayRequest,
  loadOptions: Nullable<LoadOptions>,
): ActionLoadPlayRequest {
  return {
    type: "player-manager/load-play-request",
    payload: {
      playRequest,
      loadOptions,
    },
  };
}
