import { areVideoAdsPlayingAtom } from "@sunrise/ads";
import { Atom, atom } from "jotai";
import { selectAtom } from "jotai/utils";

import { playerAtom } from "./player.atom";

export function playerIsVisibleAtom(currentAtom = playerAtom): Atom<boolean> {
  const isVisibleAtom = selectAtom(
    currentAtom,
    (state) => state.shouldBeVisible,
  );

  const slice = atom((get) => {
    const isVisible = get(isVisibleAtom);
    const adsPlaying = get(areVideoAdsPlayingAtom);

    return adsPlaying ? true : isVisible;
  });

  slice.debugLabel = playerIsVisibleAtom.name;

  return slice;
}
