// TODO: Figure out how to load types for shaka-player
/// <reference types="shaka-player" />
import { Nullable } from "@sunrise/utils";
import { Stream } from "@sunrise/yallo-stream";
// @ts-expect-error: see above
import shakaPlayer from "shaka-player/dist/shaka-player.compiled";
import { DeepPartial } from "ts-essentials";

import { getShakaConfigForPlayRequestAndStream } from "./helpers/get-shaka-config-for-play-request-and-stream";
import {
  DEFAULT_AUDIO_CHANNELS,
  type PlayerAudioTrack,
  type PlayerSubtitleTrack,
  type TrackId,
} from "./player.types";
import { PlayerWrapper, PlayerWrapperConfigureProps } from "./player.wrapper";

export class ShakaPlayerWrapper implements PlayerWrapper {
  readonly name = "ShakaPlayer";

  readonly player: shaka.Player;

  /**
   * Defined in bits/sec.
   */
  private lastKnownBandwidth: number = 0;

  /**
   * Used for DRM FairPlay.
   */
  private contentId: Nullable<string>;

  private isDetached?: () => boolean;

  constructor(videoElement: HTMLVideoElement) {
    shakaPlayer.polyfill.installAll();
    shakaPlayer.polyfill.PatchedMediaKeysApple.install();
    this.player = new shakaPlayer.Player(videoElement);
  }

  configure(
    props: PlayerWrapperConfigureProps,
  ): DeepPartial<shaka.extern.PlayerConfiguration> {
    const {
      playRequest,
      stream,
      fairPlayCertificate,
      getPlayerBufferSettings,
      trackKnownBandwidth,
      allowParallelPrefetch,
    } = props;

    let fairPlayConfiguration;
    if (fairPlayCertificate && stream.type === "hls7_fairplay") {
      fairPlayConfiguration = {
        fairplayServerCertificate: fairPlayCertificate,
        fairplayInitDataTransform:
          this.fairplayInitDataTransform(fairPlayCertificate),
      };
    }

    const bufferSettings =
      playRequest?.type === "live"
        ? getPlayerBufferSettings.live()
        : getPlayerBufferSettings.delayed();

    const config = getShakaConfigForPlayRequestAndStream(
      stream,
      playRequest,
      bufferSettings,
      allowParallelPrefetch,
      trackKnownBandwidth,
      this.lastKnownBandwidth,
      fairPlayConfiguration,
    );
    this.player.configure(config);

    this.player
      .getNetworkingEngine()
      ?.unregisterRequestFilter(
        this.fairplayRequestFilter.bind(this, stream.provider),
      );
    this.player
      .getNetworkingEngine()
      ?.unregisterResponseFilter(this.fairplayResponseFilter);

    if (stream.type === "hls7_fairplay") {
      this.player
        .getNetworkingEngine()
        ?.registerRequestFilter(
          this.fairplayRequestFilter.bind(this, stream.provider),
        );
      this.player
        .getNetworkingEngine()
        ?.registerResponseFilter(this.fairplayResponseFilter);
    }

    this.player.configure(config);

    return config;
  }

  async load(url: string, startTime?: number) {
    await this.player.load(url, startTime);
  }

  unload(): Promise<void> {
    return this.player.unload();
  }

  attach(
    videoElement: HTMLVideoElement,
    initMediaSource: boolean,
  ): Promise<void> {
    return this.player.attach(videoElement, initMediaSource);
  }

  detach() {
    return this.player.detach();
  }

  private fairplayInitDataTransform =
    (cert: Uint8Array) =>
    (
      initData: Uint8Array,
      initDataType: string,
      drmInfo: shaka.extern.DrmInfo | null,
    ): Uint8Array => {
      if (initDataType != "skd" || !drmInfo) return initData;
      // 'initData' is a buffer containing an 'skd://' URL as a UTF-8 string.
      const skdUri = shakaPlayer.util.StringUtils.fromBytesAutoDetect(initData);
      this.contentId = skdUri.split("skd://")[1];
      return shakaPlayer.util.FairPlayUtils.initDataTransform(
        initData,
        this.contentId!,
        cert,
      );
    };

  // NOTE: do not use types shaka.* in the filters because it's not available at runtime
  private fairplayRequestFilter = (
    streamProvider: Nullable<Stream["provider"]>,
    type: shaka.net.NetworkingEngine.RequestType,
    request: shaka.extern.Request,
  ): void => {
    if (!this.player) {
      return;
    }

    if (
      type != shakaPlayer.net.NetworkingEngine.RequestType.LICENSE ||
      this.player.drmInfo()?.keySystem != "com.apple.fps.1_0"
    ) {
      return;
    }

    const base64Payload = shakaPlayer.util.Uint8ArrayUtils.toStandardBase64(
      request.body,
    );

    if (streamProvider === "greenstreams") {
      const params = "spc=" + base64Payload + "&assetId=" + this.contentId;
      // Parse the request body to the format expected by Greenstreams server
      request.body = shakaPlayer.util.StringUtils.toUTF8(params);
    } else {
      request.headers["Content-Type"] = "application/data";
      // Parse the request body to the format expected by Zattoo's server

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (request.body as any) = base64Payload;
    }
  };

  // NOTE: do not use types shaka.* in the filters because it's not available at runtime
  private fairplayResponseFilter = (
    type: shaka.net.NetworkingEngine.RequestType,
    response: shaka.extern.Response,
  ): void => {
    if (!this.player) {
      return;
    }

    if (
      type != shakaPlayer.net.NetworkingEngine.RequestType.LICENSE ||
      this.player.drmInfo()?.keySystem != "com.apple.fps.1_0"
    ) {
      return;
    }

    const responseText = shakaPlayer.util.StringUtils.fromUTF8(
      response.data,
    ).trim();
    // Decode the base64-encoded data into the format the browser expects.
    response.data =
      shakaPlayer.util.Uint8ArrayUtils.fromBase64(responseText).buffer;
  };

  getSubtitleTracks = (): {
    activeId: Nullable<TrackId>;
    options: PlayerSubtitleTrack[];
  } => {
    let activeId: TrackId | null = null;
    let options: PlayerSubtitleTrack[] = [];

    try {
      options = this.player
        .getTextTracks()
        .filter((t) => t.kind === "subtitle")
        .map((t) => {
          if (t.active) {
            activeId = getTrackId(t);
          }

          return {
            id: getTrackId(t),
            label: t.label || t.language,
            lang: t.language,
          };
        });
    } catch (e: unknown) {
      // We do not want to crash the player when we can't get the text tracks.
      // We just want to log the error.
      console.error(e);
    }

    return {
      activeId,
      options,
    };
  };

  onTracksChanged = (handleTracks: () => void): void => {
    this.player.addEventListener("trackschanged", () => handleTracks());
  };

  getAudioTracks = (): {
    activeId: Nullable<TrackId>;
    options: PlayerAudioTrack[];
  } => {
    let activeId: TrackId | null = null;

    let options: PlayerAudioTrack[] = [];

    try {
      const rawTracks = this.player.getVariantTracks();
      options = rawTracks.reduce((acc: PlayerAudioTrack[], t) => {
        const id = getTrackId(t);

        if (t.active) {
          activeId = id;
        }

        // Check if we already have this id. If so, do not add it again.
        if (acc.find((track) => track.id === id)) {
          // If found, don't repeat the track
          return acc;
        }

        const channelsCount = t.channelsCount ?? DEFAULT_AUDIO_CHANNELS;

        // Add the track to the accumulator
        acc.push({
          id,
          lang: t.language,
          label: t.label || t.language,
          format: channelsCount === 2 ? "Stereo" : "Dolby",
          channelsCount: channelsCount,
        });

        return acc;
      }, []);
    } catch (e: unknown) {
      // We do not want to crash the player when we can't get the audio tracks.
      // We just want to log the error.
      console.error(e);
    }

    return {
      activeId,
      options,
    };
  };

  selectAudioLanguage = (lang: string, channelsCount: number): void => {
    this.player.selectAudioLanguage(lang, undefined, channelsCount);
  };

  onBuffering = (onBufferingStart: () => void, onBufferingStop: () => void) => {
    this.player.addEventListener("buffering", (event: unknown) => {
      if (this.isDetached?.()) {
        return;
      }

      if (
        event === null ||
        typeof event !== "object" ||
        !("buffering" in event)
      ) {
        return;
      }

      if (event.buffering === true) {
        onBufferingStart();
      } else {
        onBufferingStop();
      }
    });
  };

  onQualityChanges = (
    initialTrackKnownBandwidth: boolean,
    trackKnownBandwidthChanged: (cb: (value: boolean) => void) => void,
  ) => {
    const handle = (value: boolean) => {
      this.player.removeEventListener(
        "mediaqualitychanged",
        this.qualityChangedHandler,
      );
      this.lastKnownBandwidth = 0;

      if (value) {
        this.player.addEventListener(
          "mediaqualitychanged",
          this.qualityChangedHandler,
        );
      }
    };

    // Initial setup
    handle(initialTrackKnownBandwidth);

    // Reflect on changes
    trackKnownBandwidthChanged((value) => {
      handle(value);
    });
  };

  private qualityChangedHandler = (
    e: Event & { mediaQuality?: shaka.extern.MediaQualityInfo },
  ) => {
    if (e.mediaQuality?.bandwidth) {
      this.lastKnownBandwidth = e.mediaQuality.bandwidth;
    }
  };

  private statsInterval?: number;
  onStats = (
    getPlayerStatsEnabled: () => boolean,
    setPlayerStats: (
      stats: shaka.extern.Stats,
      buffer: shaka.extern.BufferedInfo,
      bufferFullness: number,
    ) => void,
  ): void => {
    if (!getPlayerStatsEnabled()) {
      window.clearInterval(this.statsInterval);
      return;
    }

    this.statsInterval = window.setInterval(() => {
      setPlayerStats(
        this.player.getStats(),
        this.player.getBufferedInfo(),
        this.player.getBufferFullness(),
      );
    }, 200);
  };

  setTextTrack = (lang: Nullable<string>, visible: boolean): void => {
    if (!lang) {
      this.player.setTextTrackVisibility(false);
      return;
    }

    this.player.selectTextLanguage(lang);
    this.player.setTextTrackVisibility(visible);
  };

  onError = (cb: (e: unknown) => void): void => {
    this.player.addEventListener("error", (e) => {
      if (typeof e === "object" && e !== null && "detail" in e) {
        cb(e.detail);
      }
    });
  };

  setIsDetachedFn(isDetached: () => boolean): void {
    this.isDetached = isDetached;
  }
}

function getTrackId(t: shaka.extern.Track): TrackId {
  return `${t.language}-${
    t.channelsCount ?? DEFAULT_AUDIO_CHANNELS
  }` as TrackId;
}
