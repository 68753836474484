import { format } from "date-fns";

import { type TimeDay } from "./time.types";

/**
 * Converts `Date` to domain specific format.
 *
 * This format is used to query Epg data from the backend.
 *
 * {@link https://entwicklungspark.atlassian.net/wiki/spaces/WT/pages/2682847233/Backend+DTOs+and+APIs#Full-(by-Id) Docs on DTOs and APIs}
 */
export function dateToTimeDay(date: Date, asUtc = true): TimeDay {
  if (asUtc) {
    return date.toISOString().substring(0, 10) as TimeDay;
  }
  return format(date, "yyyy-MM-dd") as TimeDay;
}
