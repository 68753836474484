"use client";

import { TranslateFn, currentLanguageAtom } from "@sunrise/i18n";
import { nowAtom } from "@sunrise/time";
import { type Nullable } from "@sunrise/utils";
import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";

import type {
  Schedulable,
  useReadableEpgSchedulingInfoReturn,
} from "../epg.types";
import { getRelativeTimeForEpg } from "../helpers/get-relative-time-for-epg";

const EMPTY = { status: null, schedule: null };

/**
 * @param currentTime The current time. If not given, it will use the current time from the store.
 * @param schedulable The schedulable for which we want to get the easily readable scheduling.
 *
 * @returns {Object} readableSchedulingInfo
 * @returns {string} readableSchedulingInfo.status - The relative day of the schedulable or a formatted date, e.g. `live`, `today` or `Fri. Feb 23 10:00 - 11:00`.
 * @returns {string} readableSchedulingInof.schedule - The timing for the given schedulable in an easy to read format. Something like `01:23 - 02:34 (1h 11m)` or `Starts in 1 hour`.
 */
export function useReadableEpgSchedulingInfo(
  schedulable: Nullable<Schedulable>,
  t: TranslateFn,
  currentTime?: Date,
): useReadableEpgSchedulingInfoReturn {
  const [when, setWhen] = useState<useReadableEpgSchedulingInfoReturn>(EMPTY);

  const storeTime = useAtomValue(nowAtom);
  const language = useAtomValue(currentLanguageAtom);
  const now = currentTime ?? storeTime;

  useEffect(() => {
    const doAsync = async (): Promise<void> => {
      if (!schedulable) {
        setWhen(EMPTY);
        return;
      }

      setWhen(
        await getRelativeTimeForEpg(
          schedulable.startTime,
          schedulable.endTime,
          now,
          language,
          t,
        ),
      );
    };

    void doAsync();
  }, [schedulable, now, language]);

  return when;
}
