import { type ChannelId } from "@sunrise/yallo-channel";
import { type EPGEntryId, epgDetailsForEpgEntryId } from "@sunrise/yallo-epg";
import { UpsellErrorCode } from "@sunrise/yallo-upsell";
import { selectActiveReplayChannels, userAtom } from "@sunrise/yallo-user";
import { isAfter, isEqual } from "date-fns";
import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

/**
 * returns isChannelActiveForReplay: true | UpsellErrorCode and function to check if replay is active long enough that can also return UpsellErrorCode
 */

export type ActiveReplayChannel = {
  isChannelActiveForReplay: true | UpsellErrorCode;
  checkIfReplayActiveLongEnough: (
    epgId: EPGEntryId,
    startTime?: Date,
  ) => Promise<UpsellErrorCode | true>;
};

export const activeReplayChannelAtom = atomFamily(
  (param: { channelId: ChannelId }) => {
    const innerAtom = atom(async (get): Promise<ActiveReplayChannel> => {
      const user = (await get(userAtom)).data;

      // TODO: When no user deal with it properly ...
      if (!user) {
        throw new Error("no user when checking replay permissions");
      }

      const result = selectActiveReplayChannels(user);

      const channel = result?.find(
        (channels) => channels.channel_id === param.channelId,
      );

      if (!channel) {
        // Channel is not active for replay
        return {
          isChannelActiveForReplay: "UPSELL_REPLAY_NOT_ENABLED",
          checkIfReplayActiveLongEnough: async () =>
            "UPSELL_REPLAY_NOT_ENABLED",
        };
      }

      const checkIfReplayActiveLongEnough = async (
        epgId: EPGEntryId,
        epgStartTime?: Date,
      ) => {
        let startTime = epgStartTime;

        if (!startTime) {
          const epgEntry = await get(epgDetailsForEpgEntryId({ epgId }));

          startTime = new Date(epgEntry.actual_start);
        }

        const activatedAt = new Date(channel.activated_at);

        return isAfter(startTime, activatedAt) ||
          isEqual(startTime, activatedAt)
          ? true
          : "UPSELL_REPLAY_NOT_ENABLED_LONG_ENOUGH";
      };

      return {
        isChannelActiveForReplay: true,
        checkIfReplayActiveLongEnough,
      };
    });

    innerAtom.debugLabel = `activeReplayChannel(${param.channelId})`;
    return innerAtom;
  },
);
