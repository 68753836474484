import { type Nullable } from "@sunrise/utils";
import { ChannelId, allChannelsAtom } from "@sunrise/yallo-channel";
import { atom } from "jotai";
import { isNil } from "lodash";

export const queryParamChannelIdAtom = atom(
  async (get): Promise<Nullable<ChannelId>> => {
    const params = new URLSearchParams(window.location.search);
    const channel = params.get("channel");

    // NOTE: in test environments, use default channel and support alias names for easy access
    if (import.meta.env.MODE !== "production") {
      const channels = (await get(allChannelsAtom)).data;

      // TODO remove alias_name with NG backend
      function getChannelId(id: Nullable<string>): Nullable<ChannelId> {
        return id
          ? channels.result.find(
              (c) => c.id === id || c.alias_name.includes(id),
            )?.id
          : null;
      }

      const channelId = getChannelId(channel);
      if (!isNil(channelId)) {
        return channelId;
      }

      return channels.result[0]?.id ?? null;
    }

    return channel as Nullable<ChannelId>;
  },
);
