import * as Sentry from "@sentry/browser";
import * as SentryReact from "@sentry/react";
import { type SeverityLevel } from "@sentry/types";
import { type Nullable } from "@sunrise/utils";

export function initMonitoring(
  setup: {
    environment: string;
    isProdMode: boolean;
    dsn: Nullable<string>;
  },
  config?: Partial<Pick<SentryReact.BrowserOptions, "initialScope">>,
): void {
  if (!setup.dsn) {
    console.warn("Monitoring is disabled.");
    return;
  }

  SentryReact.init({
    environment: setup.environment,
    dsn: setup.dsn,
    initialScope: config?.initialScope,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        // We will not mask any text by default. Masking will be opt-in.
        maskAllText: false,
      }),
    ],
    tracePropagationTargets: ["localhost", /^\//, /.*\.w4a\.tv.*/],

    // Performance Monitoring
    tracesSampleRate: setup.isProdMode ? 0.1 : 1, // Capture 100% of the transactions, reduce in production!

    // Session Replay
    replaysSessionSampleRate: setup.isProdMode ? 0.1 : 1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: setup.isProdMode ? 0.1 : 1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  window.addEventListener(
    "error",
    function handleErrorFromOutsideOfReact(error) {
      if (error instanceof Error) {
        SentryReact.captureException(error);
      }

      if (typeof error === "string") {
        SentryReact.captureMessage(error);
      }
    },
  );
}

// log manually an error to sentry
export function logError(error: Error): void {
  SentryReact.captureException(error);
}

// log manually a message to sentry
export function logMessage(message: string, severity: SeverityLevel): void {
  SentryReact.captureMessage(message, severity);
}
