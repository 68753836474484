import { AssetId } from "@sunrise/asset";
import { type EPGEntryId } from "@sunrise/yallo-epg";

import { type RecordingGroupId, type RecordingId } from "./recordings.types";

export const endpoints = {
  recordingItems: (host: string, searchParam?: { page: number }): string => {
    const url = new URL(`${host}/alderaan/recording-items`);
    Object.entries(searchParam ?? {}).forEach(([k, v]) => {
      url.searchParams.append(k, v.toString());
    });
    return url.href;
  },
  recordingStatus: (host: string): string =>
    `${host}/alderaan/recording-status`,
  singleRecording: (host: string, id: RecordingId): string =>
    `${host}/alderaan/recordings/${id}`,
  recordingGroups: {
    recordingsGroupItems: (
      host: string,
      recordingGroupId: RecordingGroupId,
      traverseChildGroups: boolean,
    ): string =>
      `${host}/alderaan/recording-groups/${recordingGroupId}/recordings?order_by_end_time=desc&traverse_child_groups=${traverseChildGroups.toString()}`,
    recordingsGroup: (
      host: string,
      recordingGroupId: RecordingGroupId,
    ): string => `${host}/alderaan/recording-groups/${recordingGroupId}`,
  },
  recordingSchedules: {
    create: (host: string) => `${host}/alderaan/recording-schedules`,
    deleteByEpgEntryId: (host: string, epgEntryId: EPGEntryId) =>
      `${host}/alderaan/recording-schedules/?epg_entry_id=${epgEntryId}`,
    cancelByAssetId: (host: string, assetId: AssetId) =>
      `${host}/alderaan/recording-schedules/cancel?asset_id=${assetId}`,
    haveRecordingSchedules: (host: string, assetId: AssetId) =>
      `${host}/alderaan/recording-schedules/?asset_id=${assetId}&pagesize=0`,
  },
  recordingStats: (host: string): string => `${host}/alderaan/recording-stats`,
  recordingGroupsByAssetId: (host: string, assetId: AssetId): string =>
    `${host}/alderaan/recording-groups/?asset_id=${assetId}`,
};
