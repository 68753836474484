export const endpoint = {
  refreshToken: (host: string) => `${host}/kamino/auth/refresh`,
  invalidateAllTokens: (host: string) =>
    `${host}/kamino/users/me/test_invalidate_all_tokens`,
  invalidateAccessToken: (host: string) =>
    `${host}/kamino/users/me/test_invalidate_access_tokens`,
  anonymous: (host: string) => `${host}/kamino/users/anonymous/`,
  login: (host: string, userId: string, deviceTypeName: string) =>
    `${host}/kamino/auth/login?user_id=${userId}&device_type_name=${deviceTypeName}`,
};
