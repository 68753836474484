import { publicApi } from "@sunrise/http-client";

import { Vendor, VendorError, endpoints } from "@/modules/vendor";

export async function fetchVendor(
  host: string,
  vendorId: string,
  vendorSecret: string,
  vendorHost: string,
): Promise<Vendor> {
  try {
    const response = await publicApi.get<Vendor>(
      endpoints.vendor(host, vendorId),
      {
        headers: {
          "x-vendor-secret": vendorSecret,
          "x-vendor-host": vendorHost,
        },
      },
    );
    return response.data;
  } catch (e: unknown) {
    if (e instanceof Error) {
      throw new VendorError(e.message, { cause: e });
    }

    throw e;
  }
}
