import { videoAdsAtom } from "@sunrise/ads";
import { atom } from "jotai";

/**
 * The concept of detaching means that the player should unload itself and not attempt to do anything with the player.
 * For Tizen this is necessary since Tizen can only have a single video element at a time. Therefore SDK ads will cannibalize the video element.
 * As soon as Tizen loads, the player should be detached. When Tizen is done, the player should re-attach.
 */
export const playerShouldDetachAtom = atom((get) => {
  return !!get(videoAdsAtom).adConfig;
});
