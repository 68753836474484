import { Channel, ChannelLogo } from "./channel.types";

export function getStreamUrl(channel: Channel): string | null {
  return channel.naboo_urls?.streams ?? null;
}

type LogosContainer = {
  logos: ChannelLogo[];
};

export function getChannelLogo(
  channel: Channel | LogosContainer,
  preferred: "black" | "white" = "black",
): ChannelLogo | null {
  return (
    channel.logos.find((logo) => logo.type === preferred) ??
    channel.logos[0] ??
    null
  );
}
