import { isSSR } from "@sunrise/utils";

// save a unique client id to local storage to identify the user between sessions
export function getClientID(): string | undefined {
  if (isSSR()) {
    return;
  }

  const key = "client_id";
  const existing = localStorage.getItem(key);

  if (existing) return existing;

  const id = crypto.randomUUID();
  localStorage.setItem(key, id);

  return id;
}
