// TODO: Figure out how to load types for shaka-player
 
/// <reference types="shaka-player" />
import { type Nullable } from "@sunrise/utils";
import { selectAtom } from "jotai/utils";
import { atomWithReducer } from "jotai/vanilla/utils";
import { type DeepPartial } from "ts-essentials";

const CUTOFF_TIME = 1_500_000_000;

type PlayerStats = {
  enabled: boolean;
  raw: Nullable<shaka.extern.Stats>;
  config: Nullable<DeepPartial<shaka.extern.PlayerConfiguration>>;
  buffer: Nullable<shaka.extern.BufferedInfo>;
  bufferFullness: Nullable<number>;
  loadTime: Nullable<Date>;
  loadedTime: Nullable<Date>;
};

type ToggleEnabledAction = {
  type: "player-stats/toggle-enabled";
};

type SetEnabledAction = {
  type: "player-stats/set-enabled";
};

type StartPlayoutAction = {
  type: "player-stats/start-playout";
  payload: {
    at: Date;
  };
};

type DetectedPlayableAction = {
  type: "player-stats/detected-playable";
  payload: {
    at: Date;
  };
};

type SetRawStatsAction = {
  type: "player-stats/set-raw";
  payload: {
    raw: shaka.extern.Stats;
    buffer: shaka.extern.BufferedInfo;
    bufferFullness: number;
  };
};

type SetPlayerConfig = {
  type: "player-stats/set-player-config";
  payload: {
    config: DeepPartial<shaka.extern.PlayerConfiguration>;
  };
};

type Actions =
  | ToggleEnabledAction
  | SetEnabledAction
  | SetRawStatsAction
  | SetPlayerConfig
  | StartPlayoutAction
  | DetectedPlayableAction;

export const playerStatsAtom = atomWithReducer<PlayerStats, Actions>(
  {
    enabled: false,
    buffer: null,
    config: null,
    bufferFullness: null,
    raw: null,
    loadTime: null,
    loadedTime: null,
  },
  (v, action) => {
    switch (action?.type) {
      case "player-stats/toggle-enabled":
        return { ...v, enabled: !v.enabled };
      case "player-stats/set-enabled":
        return { ...v, enabled: true };
      case "player-stats/set-raw":
        return {
          ...v,
          raw: action.payload.raw,
          buffer: action.payload.buffer,
          bufferFullness: action.payload.bufferFullness,
        };
      case "player-stats/start-playout":
        return {
          ...v,
          raw: null,
          loadTime: action.payload.at,
          loadedTime: null,
        };
      case "player-stats/detected-playable":
        return { ...v, loadedTime: action.payload.at };
      case "player-stats/set-player-config":
        return { ...v, config: action.payload.config };
      default:
        return v;
    }
  },
);

export function actionPlayerStatsToggleEnabled(): ToggleEnabledAction {
  return {
    type: "player-stats/toggle-enabled",
  };
}

export function actionPlayerStatsSetEnabled(): SetEnabledAction {
  return {
    type: "player-stats/set-enabled",
  };
}

export function actionPlayerStatsSetRawStats(
  stats: shaka.extern.Stats,
  buffer: shaka.extern.BufferedInfo,
  bufferFullness: number,
): SetRawStatsAction {
  return {
    type: "player-stats/set-raw",
    payload: {
      raw: stats,
      buffer,
      bufferFullness,
    },
  };
}

export function actionPlayerStatsStartPlayout(at: Date): StartPlayoutAction {
  return {
    type: "player-stats/start-playout",
    payload: {
      at,
    },
  };
}

export function actionPlayerStatsDetectedPlayable(
  at: Date,
): DetectedPlayableAction {
  return {
    type: "player-stats/detected-playable",
    payload: {
      at,
    },
  };
}

export function actionPlayerStatsSetConfiguration(
  config: DeepPartial<shaka.extern.PlayerConfiguration>,
): SetPlayerConfig {
  return {
    type: "player-stats/set-player-config",
    payload: {
      config,
    },
  };
}

export const selectPlayerStatsEnabled = selectAtom(
  playerStatsAtom,
  (s) => s.enabled,
);

export const selectPlayerStatsConfiguration = selectAtom(
  playerStatsAtom,
  (s) => s.config,
);

export const selectPlayerSimpleStats = selectAtom(playerStatsAtom, (s) => {
  if (!s.raw || !s.enabled) {
    return null;
  }

  const {
    raw: {
      bufferingTime,
      completionPercent,
      corruptedFrames,
      decodedFrames,
      drmTimeSeconds,
      droppedFrames,
      estimatedBandwidth,
      gapsJumped,
      height,
      licenseTime,
      liveLatency,
      loadLatency,
      manifestTimeSeconds,
      maxSegmentDuration,
      pauseTime,
      playTime,
      stallsDetected,
      streamBandwidth,
      width,
    },
    buffer,
    bufferFullness,
  } = s;

  const loadTime =
    s.loadTime && s.loadedTime
      ? (s.loadedTime.getTime() - s.loadTime.getTime()) / 1000
      : null;

  return {
    drmTimeSeconds: round(drmTimeSeconds, 3),
    licenseTime: round(licenseTime, 3),
    maxSegmentDuration: round(maxSegmentDuration, 3),
    manifestTimeSeconds: round(manifestTimeSeconds, 3),
    completionPercent: round(completionPercent),
    corruptedFrames,
    decodedFrames,
    droppedFrames,
    stallsDetected,
    gapsJumped,
    loadTime: round(loadTime),
    bufferInfo: getBufferInfo(buffer, bufferFullness),
    bufferingTime: round(bufferingTime),
    pauseTime: round(pauseTime),
    playTime: round(playTime, 0),
    streamBandwidth,
    estimatedBandwidth: round(estimatedBandwidth, 0),
    liveLatency: round(liveLatency),
    loadLatency: round(loadLatency),
    height,
    width,
  };
});

function round(v: Nullable<number>, decimals: number = 2): Nullable<number> {
  return v === null ? null : Number(Number(v).toFixed(decimals));
}

function getBufferInfo(
  buffer: Nullable<shaka.extern.BufferedInfo>,
  fullness: Nullable<number>,
) {
  if (!buffer || !buffer.total[0]) {
    return null;
  }

  const isLinear = buffer.total[0].start > CUTOFF_TIME;
  const duration = round(buffer.total[0].end - buffer.total[0].start, 2);
  return {
    start: isLinear ? new Date(buffer.total[0].start * 1000) : undefined,
    end: isLinear ? new Date(buffer.total[0].end * 1000) : undefined,
    duration,
    fullness: round(fullness, 2),
  };
}
