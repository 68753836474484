import { type EPGEntry } from "@sunrise/yallo-epg";
import { UpsellError } from "@sunrise/yallo-upsell";
import { subMinutes } from "date-fns";

import { isInReplayWindow } from "./is-in-replay-window";

/**
 * Validate replay window & grab correct replay start time for the stream.
 * This is combined because in order to get the correct start time, we need to know the replay window.
 * And in order to validate if something can be replayed we also need the replay window.
 * Will also return 1 minute earlier because we noticed that the start of the replay windw usually doesn't contain the first few seconds.
 * But this is OK since we seek into the replay stream to the correct time we want to show for the users.
 *
 * NOTE: Basic replay permission validation is done on the player manager guard.
 *       It is not repeated here.
 *
 * @throws
 *  UpsellError when replay should not be allowed for some reason.
 */
export function getReplayStartTime(epg: EPGEntry, now: Date): Date {
  const actualStart = new Date(epg.actual_start);

  const { result, window } = isInReplayWindow(actualStart, now);
  if (!result) {
    throw new UpsellError("UPSELL_OUTSIDE_REPLAY_WINDOW");
  }

  // Make sure that we don't start before the replay window starts.
  // And also make it start 1 minute earlier due to YALLOTV-16632. So it definitely contains the start of the replay event.
  return subMinutes(
    new Date(Math.max(actualStart.getTime(), window.start.getTime())),
    1,
  );
}
